<mat-toolbar cclass="side-menu-header">

  <mat-toolbar-row >
    <img src="../../../assets/mobility-mojo/logo.white.png" style="height: 20px;">
    <span style="font-size: 1.8rem; position: relative; top: 1px;">&nbsp;{{this.name}}</span>
    <span style="flex: 1 1 auto;"></span>
  </mat-toolbar-row>

</mat-toolbar>

<mat-list>
  <div *ngFor="let menuItem of sideMenuModel.menuItems">
    <mat-list-item>
      <button cclass="menu-item" mat-button style=""
              (click)="onClick(menuItem)"
              [disabled]="menuItem.disabled()">
        <mat-icon aria-hidden="false" aria-label="{{menuItem.label()}}">{{menuItem.matIcon}}}</mat-icon><span>&nbsp;{{menuItem.label()}}</span>
      </button>
    </mat-list-item>

    <mat-divider style="margin: 15px 0;"></mat-divider>

  </div>

  <mat-list-item *ngIf="this.sessionContext.isAuthenticated && this.sessionContext.isAdministrator">
    <mat-form-field style="width: 100%">
      <mat-label>Active Client</mat-label>
      <mat-select [(ngModel)]="sessionContext.clientKey" (selectionChange)="onClientChanged(sessionContext.clientKey)">
        <mat-option *ngFor="let client of clients" [value]="client.clientKey">{{client.value.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>

</mat-list>

<div cclass="version-info">
  {{version}}
</div>
