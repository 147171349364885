<div cclass="session-checker" *ngIf="!sessionContext.sessionIsReady">

  <div *ngIf="!userIsReady && !userIsLoggedOut">
      <mat-spinner [diameter]="130"></mat-spinner>
      <span>{{intermediaStatus}}</span>
  </div>

  <div *ngIf="userIsLoggedOut">
    <sign-in
      (passwordResetRequested)="onPasswordResetRequested($event)">
    </sign-in>
  </div>

</div>
