import {NocoClusterId, NocoClusterSet} from "../../nocodb/model/NocoCluster";
import {AppClusterType} from "./AppClusterType";
import {NocoProductClusterSet} from "../../nocodb/model/NocoProductCluster";

export class AppClusterTypeSet {

  value: AppClusterType[] = [];
  valueByKey: { [clusterId: NocoClusterId]: AppClusterType } = {};

  public constructor( nocoClusters: NocoClusterSet, nocoProductClusters: NocoProductClusterSet ) {
    for( const nocoProductCluster of nocoProductClusters.values ) {
      const nocoCluster = nocoClusters.valuesById[nocoProductCluster.value.ClusterId];

      try {
        const appClusterType = AppClusterType.build(nocoCluster, nocoProductCluster);
        this.value.push(appClusterType);
        this.valueByKey[appClusterType.value.clusterId] = appClusterType;
      }
      catch (e)
      {
        console.error(`bad shiz ${nocoCluster.value.Id}`);
      }
    }
  }
}
