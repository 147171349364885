import {Injectable} from '@angular/core';
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {FirebaseProperty} from "../javascript.lib.mojo-base/firebase/realtime-database/properties/FirebaseProperty";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {SessionStorageWrapper} from "../common/util/SessionStorageWrapper";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {PropertyContext} from "./property-context";
import {BehaviorSubject} from "rxjs";
import {EProductType, ProductTypeOptions} from "../javascript.lib.mojo-base/model/ProductType";
import {ProductBuilder} from "../service.product-builder/product-builder.service";
import {Subscriptions2} from "../javascript.lib.mojo-base/util/Subscriptions2";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {AppStorage} from "../app/AppStorage";

@Injectable(
  {providedIn: 'root'}
)
export class PropertyService {

  private static _log = LoggerFactory.build( 'PropertyService' );
  private properties: AppProperty[];
  private property: AppProperty;
  private _hasNoProperties: boolean = false;
  private _subscriptions: Subscriptions2 = new Subscriptions2();

  public propertySubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public propertyContext: PropertyContext|null = null;

  get hasNoProperties() {
    return this._hasNoProperties;
  }

  get productType() : EProductType {
    return this.propertyContext.property.value.productType;
  }

  get productDetails() : string {
    const product = this.propertyContext?.product;
    if (!product) {
      return;
    }
    const productLabel = ProductTypeOptions.getLabel(product.productType);
    const productVersion = product.version;
    return `${productLabel} ${productVersion}`;
  }

  public async getPropertiesForCurrentUser() : Promise<AppProperty[]> {

    try {
      const properties: AppProperty[] = [];
      const user = this.sessionContext.user;
      const clientKey = user.clientKey

      if (!user || !user.value || !clientKey) {

        PropertyService._log.error("Error getting user", "user", user);

      } else if (this.sessionContext.isAdministrator) {

        PropertyService._log.info("Using temporary key", "user.temporaryPropertyKey", user.value.temporaryPropertyKey)

        const temporaryPropertyKey = user.value.temporaryPropertyKey;
        const property = await FirebaseProperty.readReferenceRedux(this.firebase, clientKey, temporaryPropertyKey);
        if (property) {
          properties.push(property);
        }

      } else {

        PropertyService._log.info("About to get list for", "user", user);
        if (user.value.propertyKeys) {
          for (const propertyKey of user.propertyKeys) {
            let property = await FirebaseProperty.readReferenceRedux(this.firebase, clientKey, propertyKey);
            if (property) {
              PropertyService._log.info("Have property for propertyKey", propertyKey);
              properties.push(property);
            }
          }
        }
      }

      this._hasNoProperties = !properties || properties.length == 0;
      //this.loadingReadySubject.next(!this._hasNoProperties);

      return properties;

    } catch (e) {
      PropertyService._log.error("Error in getAllPropertiesForUser", e);
      this._hasNoProperties = true;
      //this.loadingReadySubject.next(false);
    }
  }

  public async getPropertyForCurrentUser(propertyKey: string) : Promise<AppProperty> {
    if (!propertyKey) {
      return;
    }

    if (this.property && this.property.propertyKey == propertyKey) {
      return this.property;
    }

    if (this.properties && this.properties[propertyKey]) {
      this.property = this.properties[propertyKey];
    } else {
      this.property = await FirebaseProperty.readReferenceRedux(this.firebase, this.sessionContext.clientKey, propertyKey);
    }

    this._hasNoProperties = !this.property;
    return this.property;
  }

  public async getOrBuildPropertyContext(propertyKey: string) : Promise<PropertyContext> {
    if (!propertyKey) {
      return;
    }

    let property: AppProperty
    if (propertyKey && this.propertyContext?.property?.propertyKey !== propertyKey) {
      property = await this.getPropertyForCurrentUser(propertyKey);
      if (property) {
        return await this.buildPropertyContextWithProperty(property);
      }
    }

    if (!property) {
      PropertyService._log.warn("buildPropertyContext !property", "propertyKey", propertyKey ?? "null");
    }
  }

  public async buildPropertyContextWithProperty(property: AppProperty) : Promise<PropertyContext> {
    PropertyService._log.info("buildPropertyContextWithProperty", "propertyKey", property.propertyKey);

    this.propertyContext = await PropertyContext.build(this.sessionContext, property, this.productBuilder);
    this.propertySubject.next(property.propertyKey);

    return this.propertyContext;
  }

  private reset() : void {
    this.property = null;
    this.properties = null;
    this.propertyContext = null;
    this._hasNoProperties = true;
  }

  constructor(public firebase: FirebaseConnectionService,
              public sessionContext: SessionContextProvider,
              public productBuilder: ProductBuilder,
  ) {
    this._subscriptions.subscribe(sessionContext.stateSubject, (state) => {
      if (state === SessionContextState.LoggedOut) {
        PropertyService._log.info("Resetting property-service state");
        this.reset();
      }
    });
  }
}
