import {IProduct } from "./IProduct";

export class ProductHelper {

  public static cloneProduct( product: IProduct, excludePhotos = false ) {

    const answer: IProduct = {
      evaluationSections: product.evaluationSections,
      questions: product.questions,
      reportSections: product.reportSections,
      scoreRules: product.scoreRules,
      version: product.version,
      title: product.title,
      productType: product.productType,
      productId: product.productId,
      enumeratedConstants: product.enumeratedConstants,
    };

    if( excludePhotos ) {
      answer.evaluationSections = [];
      for( const aspectQuestions of product.evaluationSections ) {
        answer.evaluationSections.push( aspectQuestions.cloneWithoutPhotos() );
      }
    }

    return answer;
  }
}
