import {Component, Input} from "@angular/core";
import {AppAnswer} from "../../javascript.lib.mojo-base/model/AppAnswer";
import {Subscription} from "rxjs";
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {FormControl} from "@angular/forms";

@Component({ template: '' })
export abstract class BaseQuestion {

  private _disabled: boolean = false;
  protected _questionAnswer: AppAnswer;
  protected _dependantSubscription: Subscription|null = null;
  protected _log: ILogger;

  @Input() public formControl: FormControl = new FormControl('', []);
  @Input() showQuestionId: boolean = false;
  @Input() showFriendlyLabel: boolean = true;
  @Input() showHelpAndLabel: boolean = true;
  @Input() sectionIndex: number = 0;
  @Input() subSectionIndex: number = 0;
  @Input() questionIndex: number = 0;
  @Input() answer: AppAnswer;

  @Input() set questionAnswer(questionAnswer: AppAnswer) {
    this._questionAnswer = questionAnswer;
    this.answer = questionAnswer.answer;
  };

  getDisabled() : boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;

    if (value) {
      this.answer.value.needsAnswer = false;
      this.answer.value.hasAnswer = false;
      console.log(`Question ${this.getFriendlyLabel()} does not need an answer.`);
    } else {
      this.answer.value.needsAnswer = true;
      console.log(`Question ${this.getFriendlyLabel()} needs an answer.`);
    }

    this.runDependencies();
  }

  get questionAnswer() : AppAnswer {
    return this._questionAnswer;
  }

  getQuestionNumber() : number {
    return this.questionIndex;
  }

  getFriendlyLabel() : string | null {
      if (!this.showFriendlyLabel) {
        return null;
      }

      if (this.sectionIndex === 0) {
        return null;
      }

      return `${this.sectionIndex}.${this.subSectionIndex}.${this.questionIndex}`;
  };

  protected runDependencies() : void {
    // RL way to get the subscription to trigger
    // TODO: replace this with something cleaner
    this.answer.value = this.answer.value;
  }

  protected validateType(type: string) : void {
    if(this.answer.question.value.type !== type) {
      if (this._log)
        this._log.error('ngOnInit', `Type Error. Expected: ${type} Actual: ${this.answer.question.value.type}`);
    }
  }

  protected ngOnDestroy() : void {
    if( this._dependantSubscription ) {
      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }

  protected ngOnInit() : void {
    this.answer.answer.value.friendlyLabel = this.getFriendlyLabel();
  }

  protected  constructor() {
  }
}
