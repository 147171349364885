import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {IEnumeratedConstant} from "../../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {Component, Inject} from "@angular/core";
import {AppAnswer} from "../../../../javascript.lib.mojo-base/model/AppAnswer";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";

export interface DialogData {
  answer: AppAnswer;
}

@Component({
  selector: 'question-enum-dialog',
  templateUrl: 'question-enum-dialog.html',
  styleUrls: ['question-enum-dialog.scss']
})
export class QuestionEnumDialogComponent {


  private _log: ILogger = LoggerFactory.build('QuestionEnumDialogComponent');
  public static PANEL_CLASS = "question-enum-dialog";

  options: IEnumeratedConstant[] = [];
  selectedOption: IEnumeratedConstant = null;
  answer: AppAnswer;


  onCancelClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {

    this.answer.value.value = this.selectedOption.codeAsString;
    this.answer.value.hasAnswer = true;

    // hacky way to get the subscription to trigger
    this.answer.value = this.answer.value;

    this.dialogRef.close();
  }

  private _initSelectedOption() {

    const enumOption = this.answer.getEnumOption();

    if( null === enumOption || !this.answer.value.hasAnswer ) {
      return;
    }

    for (const option of this.options) {
      if ( enumOption.codeAsNumber === option.codeAsNumber ) {
        this.selectedOption = option;
        return;
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<QuestionEnumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
  ) {
    this.answer = data.answer;
    this._log.debug( 'this.answer', this.answer );

    this.options = this.answer.question.value.type2.typeEnum.options;
    this._initSelectedOption();
  }
}
