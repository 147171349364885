import {NgModule} from "@angular/core";
import {BaseApplicationContext} from "../common/services.application-context/BaseApplicationContext";
import {ApplicationContextProvider} from "../service.application-context/application-context";
import {BaseSessionContext} from "../common/service.session-context/BaseSessionContext";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {BaseSideMenuModel} from "../common/service.side-menu/BaseSideMenuModel";
import {SideMenuProvider} from "../service.side-menu/side-menu-provider";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {AppInfo} from "../AppInfo";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {ProductBuilder} from "../service.product-builder/product-builder.service";
import {PropertyService} from "../service.property/property-service";
import {UserService} from "../service.user/user-service";
import {SessionStorageWrapper} from "../common/util/SessionStorageWrapper";

@NgModule({
  declarations: [
  ],

  imports: [
  ],

  providers: [
    { provide: BaseApplicationContext, useExisting: ApplicationContextProvider },
    { provide: BaseSessionContext, useExisting: SessionContextProvider },
    { provide: BaseSideMenuModel, useExisting: SideMenuProvider },
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AppInfo,
    FirebaseConnectionService,
    ApplicationContextProvider,
    ProductBuilder,
    PropertyService,
    UserService,
    SessionStorageWrapper,
    SideMenuProvider,
  ]
})

export class CoreModule {}
