<div *ngIf="!sessionContext.isAuthenticated" cclass="sign-in-wrapper">

  <maintenance></maintenance>

  <div cclass="logical-unit">

    <div aclass="component-list">
      <div aclass="component-list-item">
        <div aclass="form-header">
          Please login
        </div>
      </div>
    </div>

    <mat-tab-group style="padding-left: 16px; padding-right: 16px;" [selectedIndex]="0">

      <mat-tab label="Username & Password"
               *ngIf="authenticationConfig.usernamePassword">

        <authenticate-email-password
          (signInCompleted)="onSignInCompleted()"
          (passwordResetRequested)="onResetPassword($event)"
        >
        </authenticate-email-password>

      </mat-tab>

      <mat-tab label="Single Sign On (SSO)"
               *ngIf="authenticationConfig.microsoft">

        <authenticate-microsoft
          (signInCompleted)="onSignInCompleted()">
        </authenticate-microsoft>

      </mat-tab>

      <mat-tab label="Sign in with Google"
               *ngIf="authenticationConfig.google">

        <authenticate-google
          (signInCompleted)="onSignInCompleted()">
        </authenticate-google>

      </mat-tab>

      <mat-tab label="Phone login"
               *ngIf="authenticationConfig.textMessage" >

        <authenticate-phone
          (signInCompleted)="onSignInCompleted()"
        ></authenticate-phone>

      </mat-tab>

      <mat-tab label="Email link (passwordless sign-in)"
               *ngIf="authenticationConfig.emailLink">

        <authenticate-email-link
          (signInCompleted)="onSignInCompleted()"
        ></authenticate-email-link>

      </mat-tab>



    </mat-tab-group>

  </div>

</div>


<div *ngIf="sessionContext.isAuthenticated">
  Logged in as: {{sessionContext.username}}
</div>

