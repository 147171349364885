import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MyApp } from './app.component';
import {EvaluationComponentsModule} from "../common/module.evaluation-components/evaluation-components.module";
import { RegistrationProvider } from '../service.registration/registration';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {environment} from "../environments/environment";
import { PhotosProvider } from '../common/module.evaluation-services/service.photos/photos';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {AppRoutingModule} from "./app-routing.module";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {CompletedPage} from "../page.completed/completed";
import {RoundProgressModule} from "angular-svg-round-progressbar";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRadioModule} from "@angular/material/radio";
import {FlexLayoutModule} from '@angular/flex-layout';
import {EvaluationPagesModule} from "../common/module.evaluation-pages/evaluation-pages.module";
import {MatListModule} from "@angular/material/list";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {SelectPropertyPage} from "../page.select-property/select-property";
import {DocumentsComponent} from "../page.documents/documents.component";
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BaseComponentsModule} from "../common/module.base-components/base-components.module";
import {PasswordResetPage} from "../page.password-reset/password-reset.page";
import {
  SessionCheckerComponent
} from "../common/component.session-checker/session-checker.component";
import {ScoreComponent} from "../page.completed/component.score/score";
import {NgFireworksModule} from "@fireworks-js/angular";
import {PageAuditComponent} from "../page.audit/page-audit.component";
import {PageClusterPageComponent} from "../page.cluster-page/page-cluster-page.component";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatTreeModule} from "@angular/material/tree";
import {ClusterComponent} from "../page.audit/component.cluster/cluster.component";
import {
  ClusterEditDialogComponent
} from "../page.audit/component.cluster/component.cluster-edit-dialog/cluster-edit-dialog";
import {ClusterAddDialogComponent} from "../page.audit/component.cluster-add-dialog/cluster-add-dialog";
import {ClusterCommentDialogComponent} from "../page.audit/component.cluster/component.cluster-comment/cluster-comment-dialog";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgOptimizedImage} from "@angular/common";
import {SearchBarComponent} from "../common/component.search-bar/search-bar.component";
import {MatMenuModule} from "@angular/material/menu";
import {AgGridModule} from "ag-grid-angular";
import {SideMenuComponent} from "../common/module.base-components/component.side-menu/side-menu.component";
import {PageHomeComponent} from "../page.home/page-home.component";
import {CoreModule} from "./core.module";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule
} from "@angular-material-components/datetime-picker";
import {ScrollToTopComponent} from "../common/component.scroll-to-top/scroll-to-top.component";

@NgModule({
  declarations: [
    ClusterComponent,
    ClusterAddDialogComponent,
    ClusterCommentDialogComponent,
    ClusterEditDialogComponent,
    CompletedPage,
    DocumentsComponent,
    MyApp,
    PageAuditComponent,
    PageClusterPageComponent,
    PasswordResetPage,
    ScoreComponent,
    SelectPropertyPage,
  ],

  imports: [
    CoreModule,

    // Core ...
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // Material ...
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTreeModule,

    // 3rd party ...
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    FlexLayoutModule,
    NgFireworksModule,
    RoundProgressModule,
    BrowserAnimationsModule,
    AgGridModule,

    // In-House ...
    AppRoutingModule,
    BaseComponentsModule,
    EvaluationComponentsModule,
    EvaluationPagesModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    PageHomeComponent,
    SearchBarComponent,
    ScrollToTopComponent,
    MatMenuModule,
    SessionCheckerComponent,
    SideMenuComponent,
  ],

  bootstrap: [MyApp],

  providers: [
    PhotosProvider,
    RegistrationProvider,
  ]
})
export class AppModule {}
