import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Command} from "../../../javascript.lib.mojo-base/util/Command";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {IProxyResponse} from "../../../javascript.lib.mojo-base/firebase/functions/ProxyResponse";


export class BaseProxy {

  public static readonly CONTENT_TYPE_APPLICATION_OCTET_STREAM = "application/octet-stream";
  public static readonly CONTENT_TYPE_APPLICATION_OCTET_JSON = "application/json";


  protected log = LoggerFactory.build( 'BaseProxy' );
  protected cloudFunctionsUrl: string = null;

  public async getAuthorizedOptions(idToken: string, contentType = BaseProxy.CONTENT_TYPE_APPLICATION_OCTET_STREAM ): Promise<any> {

    const authorization = `Bearer: ${idToken}`;
    const headers = new HttpHeaders({
      Authorization: authorization,
      'Content-Type': contentType
    });

    return {
      headers
    };
  }


  public async get<T>(url: string, options: any = {}, raiseErrorOnNonZeroStatus = true ): Promise<IProxyResponse<T>> {

    const httpResponse = await this.httpClient.get( url, options ).toPromise() as any;
    const proxyResponse = httpResponse as IProxyResponse<T>;

    if( raiseErrorOnNonZeroStatus && "0" !== proxyResponse.status ) {
      throw {
        url,
        status: proxyResponse.status
      }
    }

    return proxyResponse;
  }

  public async post<T>(url: string, body: any | null, options, raiseErrorOnNonZeroStatus = true ): Promise<IProxyResponse<T>> {

    const answer = new Command<T>();
    const httpResponse = await this.httpClient.post( url, body, options ).toPromise() as any;
    const proxyResponse = httpResponse as IProxyResponse<T>;

    if( raiseErrorOnNonZeroStatus && "0" !== proxyResponse.status ) {
      throw {
        url,
        status: proxyResponse.status
      }
    }

    return proxyResponse;
  }


  constructor(protected httpClient: HttpClient ) {

    this.cloudFunctionsUrl = environment.cloudFunctionsUrl;
    // this.cloudFunctionsUrl = 'http://localhost:5001/test-mojo-accessible/us-central1';
    // this.cloudFunctionsUrl = 'http://localhost:5001/mojo-accessible/us-central1';

  }
}
