import {IFirebaseConnection} from "../FirebaseConnection";
import {EvaluationStatus, IEvaluationState} from "../../model/evaluation/EvaluationStatus";
import {LoggerFactory} from "../../log/LoggerFactory";


export class FirebaseEvaluationState {

  private static _log = LoggerFactory.build( 'FirebaseEvaluationState' );

  private static _getPath(clientKey:string, productId: string, propertyKey: string) {
    return `/${clientKey}/evaluation/${productId}/${propertyKey}`;
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, clientKey:string, productId: string, propertyKey: string, evaluationState: IEvaluationState ) {
    const path = FirebaseEvaluationState._getPath( clientKey, productId, propertyKey );
    await firebaseConnection.database2.setValue<IEvaluationState>( path, evaluationState );
  }

  static async read( firebaseConnection: IFirebaseConnection, clientKey:string, productId: string, propertyKey: string): Promise<EvaluationStatus|null> {
    const path = FirebaseEvaluationState._getPath( clientKey, productId, propertyKey );
    const value = await firebaseConnection.database2.getValue<IEvaluationState>( path );

    if ( value ) {
      return new EvaluationStatus( value );
    }

    return null;
  }

  static async readAll( firebaseConnection: IFirebaseConnection, clientKey:string, productId: string ): Promise<{[key: PropertyKey]: EvaluationStatus}> {
    const answer: {[key: string]: EvaluationStatus} = {};
    const path = `/${clientKey}/evaluation/${productId}`;
    const value: {[key: string]: IEvaluationState} = await firebaseConnection.database2.getValue<{[key: PropertyKey]: IEvaluationState}>( path );

    for( const key of Object.keys( value ) ) {
      answer[key] = new EvaluationStatus( value[key] );
    }

    return answer;
  }
}
