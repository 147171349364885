import {BehaviorSubject} from "rxjs";
import firebase from 'firebase/compat/app';
import User = firebase.User;
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {IFirebaseError} from "../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Watchable} from "../../javascript.lib.mojo-base/util/Watchable";
import ConfirmationResult = firebase.auth.ConfirmationResult;
import {AuthenticatedProxy} from "../firebase/functions/AuthenticatedProxy";
import {HttpClient} from "@angular/common/http";
import {AppEvaluationUser} from "../../javascript.lib.mojo-base/model/AppEvaluationUser";
import {SessionStorageWrapper} from "../util/SessionStorageWrapper";

export enum SessionContextState {
  UserIsAuthenticating = "UserIsAuthenticating",
  UserIsAuthenticated = "UserIsAuthenticated",
  UserDataLoading = "UserDataLoading",
  UserDataLoaded = "UserDataLoaded",
  UserIsReady = "UserIsReady",
  LoggedOut = "LoggedOut",
  Error = "Error",
}


export abstract class BaseSessionContext {

  private _state: SessionContextState = SessionContextState.UserIsAuthenticating;
  private _appEvaluationUser: AppEvaluationUser|null = null;

  protected readonly _auth: AngularFireAuth;
  protected readonly _sessionStorage: SessionStorageWrapper;
  protected readonly _log: ILogger;
  protected _clientKey: string;
  protected _isAdministrator: boolean = false;
  protected _fbUser: User|null = null; // not null when authenticated

  public stateSubject = new BehaviorSubject<SessionContextState>(SessionContextState.UserIsAuthenticating);
  public clientKeySubject = new BehaviorSubject<string>(null);
  public sessionIsReady = false;
  public settingUp: boolean;

  get clientKey() : string {
    return this._clientKey;
  }

  set clientKey(value: string) {
    if (!this._isAdministrator) {
      return;
    }

    this._clientKey = value;
    this.clientKeySubject.next(value);
    this._sessionStorage.setItem(this.user.userUid, SessionStorageWrapper.CLIENT_KEY, value);

    this._log.debug(`ClientKey set in context and stored as ${value}`);
  }

  get state() : SessionContextState {
    return this._state;
  }

  get isAuthenticated() : boolean {
    return (this._fbUser != null);
  }

  get isAdministrator() : boolean {
    return this._isAdministrator;
  }

  get username() : string {
    return this._fbUser?.email;
  }

  set user(value : AppEvaluationUser ) {
    this._appEvaluationUser = value;
    this.sessionIsReady = (value !== null);
  }

  get user() : AppEvaluationUser {
    return this._appEvaluationUser;
  }

  public async getIdToken() : Promise<string> {
    return await this._fbUser.getIdToken();
  }

  public async buildAuthenticatedProxy( httpClient: HttpClient ): Promise<AuthenticatedProxy> {
    if( !this._fbUser ) {
      return null;
    }

    const idToken = await this._fbUser.getIdToken();
    return  new AuthenticatedProxy( httpClient, idToken );
  }

  public abstract signInWithEmailAndPassword(email: string, password: string ): Promise<IFirebaseError|null>;
  public abstract signInWithEmailLink(email: string, emailLink: string ): Promise<IFirebaseError|null>;
  public abstract signInWithPhone( confirmationResult: ConfirmationResult, phoneAuthCode: string ): Promise<IFirebaseError|null>;

  set state( value: SessionContextState ) {
    this._log.debug( `${this._state} => ${value}`);
    this.stateSubject.next( value );
    this._state = value;
  }

  async signOut() {
    this._log.info("BaseSessionContext ... signOut()");
    this._sessionStorage.clear();
    this._fbUser = null;
    this._clientKey = null;
    this.user = null;
    this.state = SessionContextState.LoggedOut;
    return this._auth.signOut();
  }

  protected constructor(auth: AngularFireAuth,
                        log: ILogger,
                        sessionStorage: SessionStorageWrapper
                        ) {
    this._auth = auth;
    this._log = log;
    this._sessionStorage = sessionStorage;
  }
}
