import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import firebase from "firebase/compat/app";

/**
 * Generated class for the SignInHotelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'authenticate-microsoft',
  templateUrl: 'authenticate-microsoft.component.html',
  styleUrls: ['authenticate-microsoft.component.scss'],
  standalone: true,
})
export class AuthenticateMicrosoftComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticateMicrosoftComponent' );


  @Output() signInCompleted = new EventEmitter<void>();


  private _provider: firebase.auth.OAuthProvider;

  public authError: string = null;


  public async onAuthenticate() {

    this._log.debug( 'onAuthenticate');

    try {

      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithPopup( this._provider );
      this._log.info( 'userCredential', userCredential );

    } catch (firebaseError) {

      this._log.error('firebaseError', firebaseError );
      return firebaseError as IFirebaseError;
    }


  }

  ngOnInit(): void {


    this._provider = new firebase.auth.OAuthProvider('microsoft.com');
    this._provider.setCustomParameters( {
      // client_id: environment.authenticationConfig.microsoft.clientId,
      tenant: environment.authenticationConfig.microsoft.tenantId,
      // redirect_uri:
    });
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               public dialog: MatDialog) {

  }



}
