import {Injectable} from '@angular/core';
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {NocoDbProjectProxy} from "../javascript.lib.mojo-base/nocodb/NocoDbProjectProxy";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {IProduct} from "../model.product/IProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";

@Injectable()
export class ProductBuilder {

  private _log = LoggerFactory.build( 'ProductBuilder' );
  private _nocoProduct: NocoDbProduct;
  private _productType: EProductType = null;
  private _product: IProduct = null;

  public async build(productType: EProductType) : Promise<IProduct> {

    if (!productType) {
      this._log.error(`No value for productType specified.`);
      return;
    }

    if (this._productType === productType && this._product != null) {
      this._log.info(`Getting ${productType} Product`);
      return this._product;
    }

    const projectProxy = NocoDbProjectProxy.build(this.firebase);
    this._nocoProduct = await NocoDbProduct.load(projectProxy);

    this._productType = productType;
    this._product = this._nocoProduct.buildProduct(productType);

    return this._product;
  }

  constructor(public firebase: FirebaseConnectionService) {
  }
}

