import {ValueObject} from "./ValueObject";
import {ISearchable} from "../../common/interfaces/ISearchable";


export interface IAppEvaluationUserMetaData {
  version: number[];
}

export interface IAppEvaluationUser {
  email: string;
  clientKey: string;
  firstName?: string;
  fullName?: string|string[];
  hotelKey?: string;
  jobTitle?: string;
  lastName?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
  primaryPropertyKey?: string;
  propertyKeys?: {[key: string]: string};
  temporaryPropertyKey: string;
}

// taken from `IMerckEvaluationUser` in `node.app.csv-to-json/src/model/MerckEvaluationUser.ts`
export interface IAppEvaluationUser2 extends IAppEvaluationUser {
  email: string;
  clientKey: string;
  fullName: string[];
  propertyKeys: {[key: string]: string};
  _meta: IAppEvaluationUserMetaData;
}


export class AppEvaluationUser extends ValueObject<IAppEvaluationUser> implements ISearchable {

  private _searchableString: string = null;

  clientKey: string;
  fullName: string;
  propertyKeys: string[] = [];

  static array = {
    sortByEmail:  ( evaluationUsers: AppEvaluationUser[] ) => {
      evaluationUsers.sort( (a, b) => {
        return a.value.email.localeCompare( b.value.email );
      });
    },
    filterByClientKey: ( evaluationUsers: AppEvaluationUser[], clientKey: string ) : AppEvaluationUser[] => {
      evaluationUsers = evaluationUsers.filter(u => u.clientKey == clientKey);
      evaluationUsers.sort((a, b) => {
        return a.value.email.localeCompare(b.value.email);
      });
      return evaluationUsers;
    }
  }

  get searchableString() : string {
    if( null !== this._searchableString ) {
      return this._searchableString;
    }
    const fullName = typeof this.value.fullName == 'string' ? this.value.fullName : this.value.fullName.join();
    this._searchableString = `${fullName}${this.value.email}`.replace(/ /g,'').toLowerCase();
    return this._searchableString;
  }


  public setFullName( fullName: string ) {
    this.fullName = fullName;
    const tokens = fullName.split( ' ');
    this.value.fullName = tokens;
  }

  public static build( value: any, userUid: string = null ): AppEvaluationUser {
      return new AppEvaluationUser( value as IAppEvaluationUser2, userUid );
  }

  public hasPropertyKey(propertyKey: string) : boolean {
    console.log(`hasPropertyKey ${propertyKey} ${this.value.temporaryPropertyKey}`);
    return this.propertyKeys.includes(propertyKey) || this.value.temporaryPropertyKey === propertyKey;
  }

  protected onSetValue(value: IAppEvaluationUser | null) {

    this.fullName = null;
    this.propertyKeys = [];
    this.clientKey = null;

    if( value ) {
      this.clientKey = value.clientKey;

      {
        if( value.fullName ) {
          if( 'string' === typeof value.fullName ) {
            this.fullName = value.fullName;
          } else {
            // assume it's an array
            this.fullName = value.fullName.join( ' ' );
          }
        } else {
          // fall back to the email address ...
          const indexOfAt = value.email.indexOf( "@" );
          const emailName = value.email.substring( 0, indexOfAt );
          const emailNameTokens = emailName.split( '.');
          this.fullName = emailNameTokens.join( ' ');
        }
      }
      {
        const propertyKeys = [];
        let primaryPropertyKey = value.primaryPropertyKey;
        if( primaryPropertyKey ) {
          propertyKeys.push( primaryPropertyKey );
        }
        if( value.propertyKeys ) {
          for( let propertyKey in value.propertyKeys) {
            if( propertyKey !== primaryPropertyKey ) {
              propertyKeys.push( propertyKey );
            }
          }
        }
        this.propertyKeys = propertyKeys;
      }
    }
  }

  getPrimaryPropertyKey(): string|null {
    if( this.propertyKeys.length ) {
      return this.propertyKeys[0];
    }
    return null;
  }

  private constructor(value: IAppEvaluationUser|null, public userUid: string = null) {
    super(value);
    if(value) {
      this.value = value;
    }
  }
}
