import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoProductId} from "./NocoProduct";

export type NocoClusterId = string;

export interface INocoCluster {
  Id: NocoClusterId;
  Name: string;
  TempEvaluationSectionId: number;
  TempProductId: number;
  Icon?: string;
  ProductId: NocoProductId;
}

export class NocoCluster extends ValueObject<INocoCluster> {

  protected onSetValue(value: INocoCluster | null) {
    if( value.TempProductId ) {
      value.ProductId = value.TempProductId;
    }
  }

  constructor( value: INocoCluster | null ) {
    super( value );
    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoClusterSet {
  private static readonly tableName = "cluster";

  values: NocoCluster[] = [];
  valuesById: {[id: NocoClusterId]: NocoCluster} = {};

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoCluster>> {
    return proxy.getView<INocoCluster>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoClusterSet> {
    const value: IListingReponse<INocoCluster> = await proxy.getView<INocoCluster>(this.tableName);
    return new NocoClusterSet( value.list );
  }

  public constructor( public list: INocoCluster[] ) {
    for( const rowValue of list ) {
      const reference = new NocoCluster( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
    }
  }
}

