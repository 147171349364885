
import {NocoProductId} from "./NocoProduct";
import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoEvaluationQuestionId} from "./NocoEvaluationQuestion";

export type NocoProductQuestionId = string;

export interface INocoProductQuestion {
  Id: NocoProductQuestionId;
  QuestionId: NocoEvaluationQuestionId;
  ProductId: NocoProductId;
  DependencyJson: string;
  ScoringJson: string;
  MobilityWeight: number;
  VisualWeight: number;
  AuditoryWeight: number;
  CognitiveWeight: number;
}

export class NocoProductQuestion extends ValueObject<INocoProductQuestion> {
  protected onSetValue(value: INocoProductQuestion | null) {
  }
  constructor(value: INocoProductQuestion | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoProductQuestionSet {
  private static readonly tableName = "product_question";
  private valuesByCompositeId: NocoProductQuestion[][] = [];

  values: NocoProductQuestion[] = [];
  valuesById: {[id: NocoProductQuestionId]: NocoProductQuestion} = {};

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProductQuestion>> {
    return proxy.getView<INocoProductQuestion>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductQuestionSet> {
    const value: IListingReponse<INocoProductQuestion> = await proxy.getView<INocoProductQuestion>(this.tableName);
    return new NocoProductQuestionSet(value);
  }

  public constructor(public value: IListingReponse<INocoProductQuestion>) {
    for (const rowValue of value.list) {
      const reference = new NocoProductQuestion(rowValue);
      this.values.push(reference);
      this.valuesById[rowValue.Id] = reference;

      if (!this.valuesByCompositeId[rowValue.ProductId]) {
        this.valuesByCompositeId[rowValue.ProductId] = []
      }
      this.valuesByCompositeId[rowValue.ProductId][rowValue.QuestionId] = reference;
    }
  }

  public getForProduct(productId: NocoProductId, questionId: NocoEvaluationQuestionId) : null | NocoProductQuestion  {
    if (!productId || !questionId) {
      return null;
    }
    if (!this.valuesByCompositeId[productId]) {
      return null;
    }
    return this.valuesByCompositeId[productId][questionId];
  }
}
