
import {Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  EnumeratedConstantReference,
  IEnumeratedConstant
} from "../../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {AppAnswer} from "../../../../javascript.lib.mojo-base/model/AppAnswer";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";


export interface DialogData {
  answer: AppAnswer;
}


@Component({
  selector: 'question-ternary-dialog',
  templateUrl: 'question-ternary-dialog.html',
  styleUrls: ['question-ternary-dialog.scss']
})
export class QuestionTernaryDialogComponent {

  public static PANEL_CLASS = "question-ternary-dialog";

  private _log: ILogger = LoggerFactory.build( 'QuestionTernaryDialogComponent' );

  options: IEnumeratedConstant[] = [
    EnumeratedConstantReference.notApplicable,
    EnumeratedConstantReference.no,
    EnumeratedConstantReference.yes,
  ];

  selectedOption = EnumeratedConstantReference.notApplicable;
  answer: AppAnswer;

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if( EnumeratedConstantReference.yes === this.selectedOption ) {
      this.answer.value.value = EnumeratedConstantReference.yes.codeAsNumber;
    } else if( EnumeratedConstantReference.no === this.selectedOption ) {
      this.answer.value.value = EnumeratedConstantReference.no.codeAsNumber;
    } else {
      this.answer.value.value = EnumeratedConstantReference.notApplicable.codeAsNumber;
    }
    this.answer.value.hasAnswer = true;

    // hacky way to get the subscription to trigger
    this.answer.value = this.answer.value;

    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<QuestionTernaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
  ) {
    this.answer = data.answer;
    this._log.debug( 'this.answer', this.answer );

    if (this.answer.value.hasAnswer) {
      if (EnumeratedConstantReference.yes.codeAsNumber === this.answer.value.value) {
        this.selectedOption = EnumeratedConstantReference.yes;
      } else if (EnumeratedConstantReference.no.codeAsNumber === this.answer.value.value) {
        this.selectedOption = EnumeratedConstantReference.no;
      } else {
        this.selectedOption = EnumeratedConstantReference.notApplicable;
      }
    } else {
      this.selectedOption = null;
    }
  }

}
