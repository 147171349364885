import {Watchable} from './Watchable';
import {Observable, Subscription} from 'rxjs';


export class Subscriptions2 {

  private value: Subscription[] = [];

  subscribe<T>(observable: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void  ) {
    const subscription = observable.subscribe( next, error, complete);
    this.value.push( subscription );
  }

  unsubscribe() {
    this.value.forEach( (subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
