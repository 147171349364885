import {INocoDbConfiguration} from "../../environments/FacilitiesEvaluationEnvironment";

export abstract class NocoDbProxy {

  public abstract signIn(url: string ): Promise<void>;
  public abstract get<T>(url: string ): Promise<T>;

  protected _getSignInUrl() {
    return `${this.nocoDbConfig.httpServer}/api/v1/auth/user/signin`;
  }

  protected constructor( public nocoDbConfig: INocoDbConfiguration) {
  }
}
