import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {PublicProxy} from "../../../firebase/functions/PublicProxy";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "../../component.alert-dialog/alert-dialog";
import {NgIf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";

/**
 * Generated class for the SignInHotelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'authenticate-email-link',
  templateUrl: 'authenticate-email-link.component.html',
  styleUrls: ['authenticate-email-link.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule
  ]
})
export class AuthenticateEmailLinkComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticateEmailLinkComponent' );

  private _publicProxy: PublicProxy = null;

  hide = true;

  formControls = {
    email: new FormControl(null, [ Validators.required, Validators.email ] ),
    password: new FormControl(null, [Validators.required] )
  };

  formGroup = new FormGroup({
    email: this.formControls.email,
    password: this.formControls.password,
  });



  authLinkForGroup = new FormGroup({
    email: this.formControls.email
  });

  public authLinkError: string = null;

  @Output() signInCompleted = new EventEmitter<void>();


  private _saveEmail( emailAddress: string ) {

    window.localStorage.setItem('AuthenticateEmailLinkComponent.emailAddress', emailAddress);
  }

  public static loadEmail(): string|null {

    return window.localStorage.getItem('AuthenticateEmailLinkComponent.emailAddress');
  }


  private _getEmailControlError(): string|null {

    if( this.formControls.email.hasError( 'required')) {

      return 'Email address required';
    }

    if( this.formControls.email.hasError( 'email')) {

      return 'Email address not valid';
    }

    return null;
  }


  public async sendSignInLinkToEmail( email: string ): Promise<IFirebaseError|null> {


    {
      const signInMethods = await this.afAuth.fetchSignInMethodsForEmail( email );
      this._log.debug( 'signInMethods', signInMethods );
    }

    const url = `${window.location.origin}/#/home`;
    this._log.debug( 'url', url );

    const actionCodeSettings = {

      // url: `https://${environment.postAuthLinkDomain}/finishSignUp`,
      // url: `https://facilities-test.mobilitymojo.com/`,
      url,
      handleCodeInApp: true,
      // iOS: {
      // },
      // android: {
      // }
      // dynamicLinkDomain: 'facilities-test.mobilitymojo.com',
    };




    try {

      await this.afAuth.sendSignInLinkToEmail( email, actionCodeSettings );
      this._saveEmail( email );

      return null; // no error

    } catch (e) {

      this._log.error( 'e', e );
      return e as IFirebaseError;
    }

    // ^^^ https://firebase.google.com/docs/auth/web/email-link-auth

  }



  async onAuthLinkSubmit() {

    this.authLinkError = this._getEmailControlError();

    if( this.authLinkError ) {

      return;
    }

    // const authError: IFirebaseAuthError = await this.sessionContext.sendSignInLinkToEmail( this.emailPasswordControls.email.value )
    const authError: IFirebaseError = await this.sendSignInLinkToEmail( this.formControls.email.value )
    if( authError ) {

      this._log.error( 'authError', authError );
      this.authLinkError = authError.code;
    }

    this._log.debug( `sendSignInLinkToEmail() completed` );

    const title = 'Email link completed';
    const message = "Please check your email (including your junk/spam folder)";

    const dialog = AlertDialogComponent.show( this.dialog, title, message );

    dialog.afterClosed().subscribe(result => {

      this._log.debug('dialog was closed', 'result', result );
    });

  }

  ngOnInit(): void {
    this._log.debug( 'ngOnInit ');
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               http: HttpClient,
               public dialog: MatDialog) {

    this._publicProxy = new PublicProxy( http );
  }



}
