import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {ActivatedRoute, Router} from "@angular/router";
import {PhotosProvider} from "../common/module.evaluation-services/service.photos/photos";
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {FirebaseSaver2} from "../common/util/FirebaseSaver2";
import {AppAspectAnswers} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectAnswers";
import {AppAspectPage} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectPage";
import {AspectQuestionListComponent} from "../common/module.evaluation-components/component.aspect-question-list/aspect-question-list.component";
import {AppQuestion} from "../javascript.lib.mojo-base/model/AppQuestion";
import { ChangeDetectorRef } from '@angular/core';
import {BaseApplicationContext} from "../common/services.application-context/BaseApplicationContext";
import {MatDialog} from "@angular/material/dialog";
import {AppAspectDefinition} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectDefinition";
import {AppPageDefinition} from "../javascript.lib.mojo-base/model/app/AppPageDefinition";
import {PropertyService} from "../service.property/property-service";

// TODO: remove this

@Component({
  selector: 'page-aspect-section',
  templateUrl: './page-aspect-section.component.html'
})
export class PageAspectSectionComponent implements OnInit {

  // private _log = LoggerFactory.build( 'AspectSectionComponent' );

  private aspectIndex = 0;
  sectionIndex: number = 0;

  private _firebaseSaver: FirebaseSaver2;

  name: string;
  isSaving: boolean = false;
  private _aspectQuestions: AppAspectDefinition;
  aspectAnswers: AppAspectAnswers;

  pageAnswers: AppAspectPage = null;


  @Input() propertyKey: string = null;
  @ViewChild(AspectQuestionListComponent) questions: AspectQuestionListComponent;

  sectionTitle: string = 'hey hey'

  getSectionAnswers( sectionIndex: number ): AppAspectPage {

    const sectionQuestions: AppPageDefinition = this.aspectAnswers.aspectQuestions.evaluationSections[sectionIndex];
    return new AppAspectPage( sectionQuestions, this.aspectAnswers );
  }

  showIssuesToast() {
    this._snackBar.open( "Check for issues", null, {
      duration: 2000,
    });
  }


  async saveAspectAndPhotos( goingToHomeAfter: boolean) {

    this.isSaving = true;
    try {

      if( goingToHomeAfter ) {

        for( const question of this._aspectQuestions.questionSet.questions ) {

          // force some sort of an answer to be created for all questions
          this.aspectAnswers.getAnswer( question );
        }
      }

      this.aspectAnswers.scrubMarkupTagsFromTextAnswers(); // report-7152.remediation.md: Page 19: Lack of Input Validation(stored)

      // await this._firebaseSaver.saveAspectAndPhotos(
      //   this.sessionContext.propertyContext.propertyKey,
      //   this.aspectAnswers );

    } finally {
      this.isSaving = false;
    }
  }

  private shouldSkipIndex( sectionIndex ): boolean {

    const sectionQuestions = this.aspectAnswers.aspectQuestions.evaluationSections[sectionIndex];

    // // skip the photos for the banking product
    // if( this.productIsBanking ) {
    //
    //   if( 1 === sectionQuestions.questions.length ) {
    //
    //     if( sectionQuestions.questions[0].isPhoto ) {
    //
    //       return true;
    //     }
    //   }
    // }


    const dependant = sectionQuestions.value.dependantDescriptor;
    if( !dependant ) {

      return false;
    }

    const dependantQuestion: AppQuestion = this.aspectAnswers.aspectQuestions.questionSet.questionByKey[dependant.questionKey];
    if( !dependantQuestion ) {

      console.error( '!dependantQuestion', sectionIndex, dependant );
      return false;
    }

    const dependantAnswer = this.aspectAnswers.getAnswer( dependantQuestion );
    if( !dependantAnswer ) {

      console.error( '!dependantAnswer', dependantQuestion );
      return false;
    }

    if( dependantAnswer.isTruthy(dependant)) {

      return false;
    }

    console.log( 'dependantAnswer', dependantAnswer );
    return true;
  }



  async onPrevious() {

    this.questions.hasIssues(); // highlight the issues at least before moving on

    await this.saveAspectAndPhotos( false );

    if( 0 === this.sectionIndex ) {

      // this.navCtrl.pop();
      AppRouteManifest.HOME.navigate( this.router );
      return;

    } else {

      let nextSectionIndex = this.sectionIndex-1;

      while( this.shouldSkipIndex(nextSectionIndex)) {

        nextSectionIndex--;
      }

      AppRouteManifest.ASPECT_SECTION.navigateToPage( this.router, this.aspectIndex, nextSectionIndex );
    }

  }

  async onNext() {

    // if( this.questions.hasIssues() ) {
    //
    //   this.showIssuesToast();
    //   return;
    // }
    this.questions.hasIssues(); // highlight the issues at least before moving on


    await this.saveAspectAndPhotos(false );

    const evaluationSectionCount = this.aspectAnswers.aspectQuestions.evaluationSections.length;

    if( evaluationSectionCount === (this.sectionIndex+1)) {

      // this.navCtrl.pop();
      await this.saveAspectAndPhotos( true );
      AppRouteManifest.HOME.navigate( this.router );

    } else {

      let nextSectionIndex = this.sectionIndex+1;

      // if( this.shouldSkipIndex(nextSectionIndex) ) {
      //
      //   nextSectionIndex++;
      //   while( nextSectionIndex < evaluationSectionCount && this.shouldSkipIndex(nextSectionIndex)) {
      //
      //     nextSectionIndex++;
      //   }
      // }
      while( nextSectionIndex < evaluationSectionCount && this.shouldSkipIndex(nextSectionIndex)) {

        nextSectionIndex++;
      }
      if( nextSectionIndex < evaluationSectionCount ) {
        AppRouteManifest.ASPECT_SECTION.navigateToPage( this.router, this.aspectIndex, nextSectionIndex);
      } else {
        await this.saveAspectAndPhotos( true );
        AppRouteManifest.HOME.navigate( this.router );
      }
    }
  }

  sessionIsReady() {
    this.init();
  }

  async init() {
    this._aspectQuestions = this.propertyService.propertyContext.product.evaluationSections[this.aspectIndex];

    {
      const sectionName = this._aspectQuestions.value.name;
      this.sectionTitle = `Section ${this.aspectIndex+1}: ${sectionName}`;
    }

    this.aspectAnswers =  this.propertyService.propertyContext.aspectAnswers[this.aspectIndex];
    this.pageAnswers = this.getSectionAnswers( this.sectionIndex );

    this.changeDetector.markForCheck();
  }

  ngOnInit() {

    this.route.paramMap.subscribe( async (params) => {

      this.aspectIndex = await AppRouteManifest.ASPECT_SECTION.getAspectIndex( this.route );
      this.sectionIndex = await AppRouteManifest.ASPECT_SECTION.getSectionIndex( this.route);

      if( this.sessionContext.sessionIsReady) {

        await this.init();
      }
    });


  }

  constructor( public router: Router,
               private route: ActivatedRoute,
               public applicationContext: BaseApplicationContext,
               public sessionContext: SessionContextProvider,
               public propertyService: PropertyService,
               public photos: PhotosProvider,
               public afDb: AngularFireDatabase,
               private _snackBar: MatSnackBar,
               public changeDetector:ChangeDetectorRef,
               public dialog: MatDialog
  ) {

    this._firebaseSaver = new FirebaseSaver2( afDb, photos, dialog );

  }


}
