

<div *ngIf="!sessionContext.isAuthenticated">


  <div aclass="component-list">



      <div aclass="component-list-item">
        <div style="width: 100%; height: 12px;">
        </div>
      </div>

      <div aclass="component-list-item" style="text-align: right;">
        <button mat-raised-button color="primary" (click)="onAuthenticate()">Authenticate</button>
      </div>

    <div *ngIf="authError" aclass="component-list-item">
      <div aclass="form-error">
        {{authError}}
      </div>
    </div>


    <div aclass="component-list-item">
        <div style="width: 100%; height: 8px;">
        </div>
      </div>

  </div>



</div>


