import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppParentChild, IAppParentChild} from "../../../model/app.cluster/AppParentChild";
import {EAppReferenceType} from "../../../model/cg/core/AppReferenceType";
import {FirebaseMMValueObject} from "./FirebaseMMValueObject";
import {AppTypedReferenceString} from "../../../model/cg/core/AppTypedReference";
import {AppParentChildSet} from "../../../model/app.cluster/AppParentChildSet";
import {EProductType} from "../../../model/ProductType";

export class FirebaseParentChild {

  private static _getPath( clientKey: string, propertyKey: string, productType: EProductType, parentChild: AppParentChild|null ): string {

    if( parentChild ) {
      return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.parent_child}/${parentChild._self.toString}`;
    }

    return `/${clientKey}/answer-clusters/${productType}/${propertyKey}/${EAppReferenceType.parent_child}`;
  }

  static async writeReference( firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType, parentChild: AppParentChild ): Promise<void> {
    const path = this._getPath( clientKey, propertyKey, productType, parentChild );
    FirebaseMMValueObject.addUpdate( firebaseConnection, parentChild );
    return firebaseConnection.database2.setValue( path, parentChild.value );
  }

  static async readReferences( firebaseConnection: IFirebaseConnection, clientKey: string, propertyKey: string, productType: EProductType ): Promise<AppParentChildSet> {
    const path = this._getPath( clientKey, propertyKey, productType, null );
    const values = await firebaseConnection.database2.getValue<{ [key: AppTypedReferenceString]: IAppParentChild; }|null>( path );

    if( !values ) {
      return new AppParentChildSet({});
    }

    return new AppParentChildSet( values );
  }
}
