import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {AppClusterType} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {AppCluster} from "../javascript.lib.mojo-base/model/app.cluster/AppCluster";
import {NocoDbProduct} from "../javascript.lib.mojo-base/nocodb/NocoDbProduct";
import {FirebaseCluster} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseCluster";
import {AppParentChild} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChild";
import {FirebaseParentChild} from "../javascript.lib.mojo-base/firebase/realtime-database/answer-clusters/FirebaseParentChild";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {PropertyService} from "../service.property/property-service";
import {AppParentChildSet} from "../javascript.lib.mojo-base/model/app.cluster/AppParentChildSet";
import {AppClusterSet} from "../javascript.lib.mojo-base/model/app.cluster/AppClusterSet";

export class HierarchyBuilder {

  private _log: ILogger = LoggerFactory.build('PropertySetup');

  constructor(public property: AppProperty,
              public firebase: FirebaseConnectionService,
              public sessionContext: SessionContextProvider,
              public propertyService: PropertyService) {
  }

  public async getOrBuild(rootClusterType: AppClusterType): Promise<AppCluster> {
    if (!this.property) {
      return
    }

    const existingClusters = await FirebaseCluster.readReferences(this.firebase, this.sessionContext.clientKey,
      this.property.propertyKey, this.property.value.productType);
    const existingParentChildren = await FirebaseParentChild.readReferences(
      this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType);

    let rootCluster : AppCluster = await this._getOrBuildCluster(existingClusters, rootClusterType, true);

    await this._getOrBuildApplicableChildren(existingClusters, existingParentChildren, rootClusterType, rootCluster, 1);

    return rootCluster;
  }

  private async _getOrBuildApplicableChildren(existingClusters: AppClusterSet,
                                              existingParentChildren: AppParentChildSet,
                                              parentClusterType: AppClusterType,
                                              parent: AppCluster,
                                              depth: number) {
    if(depth === 3) {
      return;
    }

    const product = NocoDbProduct.INSTANCE;
    const childrenTypes: AppClusterType[] = product.getClusterTypeChildren(parentClusterType, true);


    for(const childType of childrenTypes) {
      const child = await this._getOrBuildCluster(existingClusters, childType);
      await this._ensureParentChildRelationship(product, existingParentChildren, parent, parentClusterType, childType, child);
      await this._getOrBuildApplicableChildren(existingClusters, existingParentChildren, childType, child, depth + 1);
    }
  }

  private async _getOrBuildCluster(existingClusters: AppClusterSet,
                                   appClusterType: AppClusterType,
                                   root: boolean = false) : Promise<AppCluster> {

    let appCluster = existingClusters.findClusterByType(appClusterType);
    if(!appCluster) {
      appCluster = appClusterType.buildAppCluster();
      if (root) {
      appCluster.value.core = true;
      appCluster.value.name = this.property.value.name;
      }
      await FirebaseCluster.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, appCluster);
    }
    return appCluster
  }

  private async _ensureParentChildRelationship(product: NocoDbProduct,
                                               existingParentChildRelationships: AppParentChildSet,
                                               parent: AppCluster,
                                               parentClusterType: AppClusterType,
                                               childType: AppClusterType,
                                               child: AppCluster) : Promise<void> {

    const hierarchy = product.clusterHierarchies.getHierarchy(parentClusterType.nocoCluster.value.Id, childType.nocoCluster.value.Id);

    const core = 1 === hierarchy.value.Core;
    const optional = 1 === hierarchy.value.Optional;
    if (child.value.core != core || child.value.optional != optional) {
      child.value.core = core;
      child.value.optional = optional;
      await FirebaseCluster.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, child);
    }

    let existingParentChildRelationship = existingParentChildRelationships.getParentChild(parent._self, child._self);
    if (!existingParentChildRelationship)  {
      const parentChild = AppParentChild.buildNew(parent, child, hierarchy.value.Sequence);
      await FirebaseParentChild.writeReference(this.firebase, this.sessionContext.clientKey, this.property.propertyKey, this.propertyService.productType, parentChild);
    }
  }
}
