<mat-toolbar aclass="header-toolbar">
  <mat-toolbar-row>
    <button mat-button (click)="sidenav.toggle()">
      <span class="material-icons">menu</span>
    </button>
    <span style="flex: 1 1 auto;"></span>
    <img src="assets/mobility-mojo/logo-and-text.white.png"
         aclass="header-toolbar-logo"
        alt="Mobility Mojo Logo">
    <span style="flex: 1 1 auto;"></span>
    <div style="width: 64px; height: 10px;">
    </div>
  </mat-toolbar-row>
</mat-toolbar>
