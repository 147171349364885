import { Injectable } from '@angular/core';
import { FirebaseEvaluationUser} from "../javascript.lib.mojo-base/firebase/realtime-database/users/FirebaseEvaluationUser";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {AppEvaluationUser} from "../javascript.lib.mojo-base/model/AppEvaluationUser";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import {SessionContextProvider} from "../service.session-context/session-context-provider";

@Injectable(
  {providedIn: 'root'}
)
export class UserService {

  private static _log = LoggerFactory.build( 'UserService' );
  private appEvaluationUser: AppEvaluationUser;

  public async getAppEvaluationUser(uid: string, forceReload: boolean = false) : Promise<AppEvaluationUser> {
    if (this.appEvaluationUser?.userUid == uid && !forceReload) {
      return this.appEvaluationUser;
    }

    const appEvaluationUser = await FirebaseEvaluationUser.readReference2(this.firebase, uid);

    if (!appEvaluationUser) {
      UserService._log.error("User not found in Firebase users", "uid", uid);
      throw new Error(`User not found: ${uid}`);
    }

    this.appEvaluationUser = appEvaluationUser;
    return this.appEvaluationUser;
  }

  public async setTemporaryKey(sessionContext: SessionContextProvider, propertyKey: string = null) : Promise<void> {
    UserService._log.info("setTemporaryKey", "isAdministrator", sessionContext.isAdministrator);
    if (!sessionContext.isAdministrator) {
      return;
    }

    const currentUser = await FirebaseEvaluationUser.readReference2(this.firebase, sessionContext.user.userUid);
    if (!currentUser) {
      throw new Error("No user record exists!");
    }

    currentUser.clientKey = sessionContext.clientKey;
    currentUser.value.temporaryPropertyKey = propertyKey;

    UserService._log.info("setTemporaryKey", "userUid", currentUser.userUid, "propertyKey", propertyKey);
    await FirebaseEvaluationUser.writeReference(this.firebase, currentUser);
  }

  public async clearTemporaryKey(sessionContext: SessionContextProvider): Promise<void> {
    UserService._log.info("clearTemporaryKey");
    return this.setTemporaryKey(sessionContext);
  }

  constructor(public firebase: FirebaseConnectionService) {
  }
}
