export abstract class ValueObject<T> {
  private _value = null;

  public set value( value: T|null) {
    this._value = value;
    this.onSetValue( this._value );
  }

  public get value(): T|null {
    return this._value;
  }

  protected abstract onSetValue(value: T|null);

  protected constructor( value: T|null ) {
    if ( value ) {
      this.value = value;
    }
  }
}
