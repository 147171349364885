

<div *ngIf="!answers">
  Loading ...
</div>


<div *ngIf="pages">

  <div *ngFor="let answer of getPage(); let questionIndex = index">

    <div [ngSwitch]="answer.question.value.type">
      <span cclass="hidden">ID:{{answer.question.value.key}} Friendly:{{answer.answer.value.friendlyLabel}}</span>

      <question-boolean *ngSwitchCase="questionTypes.TYPE_BOOLEAN"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
                        [showFriendlyLabel]="true"
                        [sectionIndex]="sectionIndex"
                        [subSectionIndex]="subSectionIndex"
                        [questionIndex]="firstQuestionOnPageIndex + questionIndex"
      >
      </question-boolean>

      <question-centimeters *ngSwitchCase="questionTypes.TYPE_CM_MEASUREMENT"
                            [answer]="answer"
                            [showQuestionId]="showQuestionId"
                            [showFriendlyLabel]="true"
                            [sectionIndex]="sectionIndex"
                            [subSectionIndex]="subSectionIndex"
                            [questionIndex]="firstQuestionOnPageIndex + questionIndex"
      >
      </question-centimeters>

      <question-enum *ngSwitchCase="questionTypes.TYPE_ENUM"
                     [answer]="answer"
                     [showQuestionId]="showQuestionId"
                     [showFriendlyLabel]="true"
                     [sectionIndex]="sectionIndex"
                     [subSectionIndex]="subSectionIndex"
                     [questionIndex]="firstQuestionOnPageIndex + questionIndex"
      >
      </question-enum>

      <question-integer *ngSwitchCase="questionTypes.TYPE_INTEGER"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
                        [showFriendlyLabel]="true"
                        [sectionIndex]="sectionIndex"
                        [subSectionIndex]="subSectionIndex"
                        [questionIndex]="firstQuestionOnPageIndex + questionIndex"
      >
      </question-integer>


      <div *ngSwitchCase="questionTypes.TYPE_LINE">
        TYPE_LINE
      </div>


      <question-photo *ngSwitchCase="questionTypes.TYPE_PHOTO"
                      [answer]="answer"
                      [showQuestionId]="showQuestionId"
      >
      </question-photo>

      <question-ternary *ngSwitchCase="questionTypes.TYPE_TERNARY"
                        [answer]="answer"
                        [showQuestionId]="showQuestionId"
                        [showFriendlyLabel]="true"
                        [sectionIndex]="sectionIndex"
                        [subSectionIndex]="subSectionIndex"
                        [questionIndex]="firstQuestionOnPageIndex + questionIndex"
      >
      </question-ternary>

      <question-text *ngSwitchCase="questionTypes.TYPE_TEXT"
                     [questionAnswer]="answer"
                     [showQuestionId]="showQuestionId"
                     [showFriendlyLabel]="true"
                     [sectionIndex]="sectionIndex"
                     [subSectionIndex]="subSectionIndex"
                     [questionIndex]="questionIndex"
      >
      </question-text>

    </div>
  </div>

</div>
