<mat-card>

  <mat-card-title>

    <div cclass="section-button">

      <div cclass="section-icon">
        <mat-icon>
          {{clusterNode.iconName?clusterNode.iconName:'corporate_fare'}}
        </mat-icon>
      </div>

      <div cclass="section-index">
        Section {{sectionIndex}}
      </div>

      <div cclass="section-title">
        <h3>
          {{clusterNode.cluster.value.name}}
          <span cclass="section-include" *ngIf="clusterNode.cluster.value.optional">
            <mat-slide-toggle class="mat-primary"
                  [(ngModel)]="clusterNode.cluster.value.include"
                  (change)="onSectionInclude()">Applicable</mat-slide-toggle>
          </span>
        </h3>
        <span style="display: none">{{clusterNode.cluster._self.toString}}</span>
      </div>

      <div cclass="section-completed">
        <completed-tick
          [completed]="clusterNode.cluster.value.completed"
        ></completed-tick>
      </div>

    </div>


  </mat-card-title>

  <mat-card-content>

    <div *ngFor="let child of clusterNode.children;" cclass="sub-section-row" >
      <button cclass="sub-section" mat-raised-button color="primary"
              disabled="{{!child.cluster.value.include}}"
              (click)="onEvaluateChild( child )">
        <div cclass="sub-section">
          <mat-icon cclass="sub-section-icon">
            {{child.iconName?child.iconName:'corporate_fare'}}
          </mat-icon>

          <span cclass="sub-section-title">
            {{child.cluster.value.name}}
            <span id="toggle-anchor"></span>
            <span style="display: none">{{child.cluster._self.toString}}</span>
          </span>

          <mat-icon cclass="sub-section-completed" aria-hidden="false" aria-label="Answer questions"
                    *ngIf="child.cluster.value.completed">
            done
          </mat-icon>

        </div>

      </button>

      <div cclass="context-menu-wrapper" *ngIf="(!child.cluster.value.optional && !canEditClusters) else fullContextMenu">
        <button mat-button mat-raised-button cclass="context-menu"
                *ngIf="clusterQuestions"
                (click)="onComment(child)"
        >
          <mat-icon aria-hidden="false" aria-label="Comment">comment</mat-icon>
        </button>
      </div>

      <ng-template #fullContextMenu>
        <div cclass="context-menu-wrapper">
          <button mat-button mat-raised-button cclass="context-menu" [matMenuTriggerFor]="contextMenu">
            <mat-icon aria-hidden="false" aria-label="Actions">more_horiz</mat-icon>
          </button>

          <mat-menu #contextMenu="matMenu">

            <div cclass="context-menu-action" mat-menu-item *ngIf="child.cluster.value.optional">
                <mat-slide-toggle class="mat-primary"
                  [(ngModel)]="child.cluster.value.include"
                  (change)="onSubSectionInclude(child.cluster)"
                  [disabled]="!clusterNode.cluster.value.include"
                  (click)="$event.stopPropagation();">Applicable
                </mat-slide-toggle>
            </div>

            <button cclass="context-menu-action" mat-menu-item
                    *ngIf="canEditClusters"
                    (click)="onEditChild(child)"
                    [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
            >
              <mat-icon aria-hidden="false" aria-label="Edit">edit</mat-icon>Edit
            </button>

            <button cclass="context-menu-action" mat-menu-item
                    *ngIf="canEditClusters"
                    [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
                    (click)="onAddSubSection()"
            >
              <mat-icon aria-hidden="false" aria-label="Add">add</mat-icon>Add
            </button>

            <button cclass="context-menu-action" mat-menu-item
                    *ngIf="clusterQuestions"
                    (click)="onComment(child)"
            >
              <mat-icon aria-hidden="false" aria-label="Comment">comment</mat-icon>Comment
            </button>

          </mat-menu>
        </div>
      </ng-template>

    </div>

  </mat-card-content>

</mat-card>

