<dialog-header [title]="'Edit'">
</dialog-header>

<form class="form__"
      [formGroup]="formGroup"
      (ngSubmit)="onSendPasswordResetEmail()"
>

  <div mat-dialog-content style="" class="mat-typography">

    <!--  type: null | 'Hallway' | 'Toilets' | 'Meeting Room';-->


    <div aclass="component-list-item">
      <div style="width: 100%; height: 12px;">
      </div>
    </div>

    <div aclass="component-list-item">
      <div style="width: 100%; height: 8px;">
      </div>
    </div>

    <div>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput placeholder="" [formControl]="formControls.name">
        <mat-error *ngIf="formControls.name.hasError('required')">
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-label>Area type</mat-label>
        <mat-select [formControl]="formControls.clusterType">
          <mat-option *ngFor="let applicableType of params.applicableTypes" [value]="applicableType">
            {{applicableType.value.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <label id="label-responsibility">Responsibility:</label>
    <mat-radio-group
      aria-labelledby="label-responsibility">
      <div>
        <mat-radio-button
          [formControl]="formControls.responsibility"
          ngDefaultControl
          [checked]="formControls.responsibility.value==='landlord'"
          value="landlord"
        >
          Landlord
        </mat-radio-button>
      </div>
      <div>
        <mat-radio-button
          [formControl]="formControls.responsibility"
          ngDefaultControl
          [checked]="formControls.responsibility.value==='tenant'"
          value="tenant"
        >
          Tenant
        </mat-radio-button>
      </div>
    </mat-radio-group>


  </div>


  <dialog-footer>


    <button mat-stroked-button color="warn" [disabled]="params.deleteDisabled"
            (click)="onDeleteClick()" cdkFocusInitial>

      <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>

      Delete
    </button>
    <span style="width: 8px;"></span>

    <button mat-stroked-button color="primary" (click)="onCancelClick()" cdkFocusInitial>
      Cancel
    </button>
    <span style="width: 8px;"></span>
    <button mat-stroked-button color="primary" (click)="onOkClick()">

      Save
    </button>
  </dialog-footer>


</form>
