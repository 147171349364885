import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {NocoEnumeratedType} from "./NocoEnumeratedType";
import {IEnumeratedConstant} from "../../model/EnumeratedConstant";
import {IListingReponse} from "../ListingReponse";
import {IEnumScore, ITypeEnum} from "../../model/AppQuestion";


export interface INocoEnumeratedValue {
  Id: number;
  Description: string;
  EnumeratedTypeId: number;
  Score: number;
  CodeAsNumber: number;
  CodeAsString: string;
}

export class NocoEnumeratedValue extends ValueObject<INocoEnumeratedValue>{

  protected onSetValue(value: INocoEnumeratedValue | null) {
  }

  toEnumeratedConstant(): IEnumeratedConstant {
    const codeAsNumber: number = this.value.CodeAsNumber ? this.value.CodeAsNumber : this.value.Id;
    const codeAsString: string = this.value.CodeAsString ? this.value.CodeAsString : `${this.value.Id}`;
    return {
      codeAsNumber,
      codeAsString,
      label: this.value.Description,
      popupLabel: null // when null, will not be rendered in the popup
    };
  }

  toEnumScore(): IEnumScore {
    const codeAsNumber: number = this.value.CodeAsNumber ? this.value.CodeAsNumber : this.value.Id;
    return {
      codeAsNumber,
      score: this.value.Score
    };
  }

  constructor( value: INocoEnumeratedValue | null ) {
    super( value );
    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoEnumeratedValueSet {

  private static readonly tableName: string = 'enumerated_value';

  enumeratedValues: NocoEnumeratedValue[] = [];
  enumeratedValuesById: {[id: number]: NocoEnumeratedValue} = {};
  _valuesByType: {[typeId: number]: NocoEnumeratedValue[] } = {};

  getValues( questionType: NocoEnumeratedType, debug = false): NocoEnumeratedValue[]|null {

    const questionTypeId = questionType.value.Id;
    let answer = this._valuesByType[questionTypeId];

    if( !answer || answer.length === 0) {
      answer = [];
      for( const candidate of this.enumeratedValues ) {
        if( questionTypeId === candidate.value.EnumeratedTypeId ) {
          answer.push( candidate );
        }
      }
      this._valuesByType[questionTypeId] = answer;
    }

    return answer;
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoEnumeratedValue>> {
    return proxy.getView<INocoEnumeratedValue>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoEnumeratedValueSet> {
    const value = await NocoEnumeratedValueSet.getValue( proxy );
    return new NocoEnumeratedValueSet( value );
  }

  public constructor( public value: IListingReponse<INocoEnumeratedValue> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoEnumeratedValue( rowValue );
      this.enumeratedValues.push( reference );
      this.enumeratedValuesById[rowValue.Id] = reference;
    }
  }
}
