import {IFirebaseBucket} from "../../javascript.lib.mojo-base/firebase/FirebaseBucket";
import {StorageReference} from "@firebase/storage";
import {IFirebaseFile} from "../../javascript.lib.mojo-base/firebase/FirebaseFile";
import {getDownloadURL, getStorage, ref} from "firebase/storage";


export class FirebaseBucketVersion9 implements IFirebaseBucket {


  private _storageRef: StorageReference = null;

  upload(destFilePath: string, config: { destination: string; }): Promise<void> {

    throw new Error("Method not implemented.");
  }
  file(srcStoragePath: string): IFirebaseFile {

    throw new Error("Method not implemented.");
  }


  private _getStorageRef(): StorageReference {

    if ( !this._storageRef ) {

      const storage = getStorage();

      this._storageRef = ref(storage);
    }

    return this._storageRef;
  }

  async getDownloadURL( path: string ): Promise<string> {

    const storageRef = this._getStorageRef();
    const imageRef = ref( storageRef, path);

    return await getDownloadURL(imageRef);
  }

  public constructor() {
  }

}
