import { IFirebaseBucket } from "../../javascript.lib.mojo-base/firebase/FirebaseBucket";
import {IFirebaseStorage} from "../../javascript.lib.mojo-base/firebase/FirebaseStorage";
import {FirebaseBucketVersion9} from "./FirebaseBucketVersion9";



export class StorageVersion9 implements IFirebaseStorage {

  private _bucket: FirebaseBucketVersion9;

  bucket(): IFirebaseBucket {

    return this._bucket;
  }

  constructor() {
    this._bucket = new FirebaseBucketVersion9();
  }

}
