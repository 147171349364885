import {NocoDbProxy} from "./NocoDbProxy";
import {MojoHttpHeaders} from "../util/MojoHttpHeaders";
import {INocoDbConfiguration} from "../../environments/FacilitiesEvaluationEnvironment";
import {ISignInRequest} from "./auth";
import * as auth from "./auth";


export class XmlHttpNocoDbProxy extends NocoDbProxy {

  private _headers: Headers;

  public async signIn() {

    const url = this._getSignInUrl();

    const headers = new Headers();
    headers.append( MojoHttpHeaders.CONTENT_TYPE.header, MojoHttpHeaders.CONTENT_TYPE.values.applicationOctetJson );
    headers.append( MojoHttpHeaders.AUTHORIZATION.header, this.nocoDbConfig.authorization );

    const payload: ISignInRequest = {
      'email': this.nocoDbConfig.user,
      'password': this.nocoDbConfig.password
    };

    const response = await fetch( url, {
      method: 'POST',
      headers,
      body: JSON.stringify( payload )
    })

    const signinResponse: auth.ISignInResponse = await response.json();

    this._headers = new Headers();
    this._headers.append( MojoHttpHeaders.CONTENT_TYPE.header, MojoHttpHeaders.CONTENT_TYPE.values.applicationOctetJson );
    this._headers.append( MojoHttpHeaders.AUTHORIZATION.header, this.nocoDbConfig.authorization );
    this._headers.append( 'xc-auth', signinResponse.token );
  }

  public async get<T>(url: string): Promise<T> {

    const response = await fetch( url, {
      method: 'GET',
      headers: this._headers
    })

    return response.json();
  }

  constructor( nocoDbConfig: INocoDbConfiguration ) {
    super( nocoDbConfig );
  }
}
