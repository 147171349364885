import firebase from 'firebase/compat/app';
import database = firebase.database;
import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppMaintenance, IAppMaintenance} from "../../../model/AppMaintenance";

export class FirebaseMaintenance {
  private static getPath(messageKey: string) : string {
    return `/maintenances/${messageKey}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection ): Promise<AppMaintenance[]> {

    const values: { [messageKey: string]: IAppMaintenance; } = await firebaseConnection.database2.getValue("/maintenances");

    const maintenances: AppMaintenance[] = [];

    for( const messageKey of Object.keys(values) ) {
      maintenances.push(new AppMaintenance(values[messageKey], messageKey));
    }

    return maintenances;
  }

  static async readReference(firebase: IFirebaseConnection, messageKey: string): Promise<AppMaintenance|null> {
    const path = this.getPath(messageKey);
    const value: IAppMaintenance = await firebase.database2.getValue<IAppMaintenance>(path);

    if (value) {
      return new AppMaintenance(value, messageKey);
    }

    return null;
  }

  static async writeReference(fbDb: database.Database, maintenanceMessage: AppMaintenance): Promise<void> {
    const path = this.getPath(maintenanceMessage.key);
    return fbDb.ref( path ).set(maintenanceMessage.value);
  }

  static async writeValue(firebaseConnection: IFirebaseConnection, messageKey: string, value: IAppMaintenance): Promise<void> {
    const path = this.getPath(messageKey);
    return firebaseConnection.database2.setValue(path, value);
  }
}
