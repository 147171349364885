import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {NocoEvaluationSection} from "./NocoEvaluationSection";
import {AppQuestion} from "../../model/AppQuestion";
import {INocoDependantDescriptor, NocoDependantDescriptor} from "./NocoDependantDescriptor";
import {AppQuestionSet} from "../../model/AppQuestionSet";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {IListingReponse} from "../ListingReponse";
import {IAppPageDefinition} from "../../model/app/AppPageDefinition";

export type NocoProductEvaluation2Id = number

export interface INocoProductEvaluation2 {
  Id: NocoProductEvaluation2Id;
  EvaluationSectionId: number;
  EvaluationPage: number;
  QuestionNo: number;
  QuestionId: number;
  DependencyJson?: string;
  DependencyId?: string;
  PageTitle: string|null;
}

export class NocoProductEvaluation2 extends ValueObject<INocoProductEvaluation2>{

  protected onSetValue(value: INocoProductEvaluation2 | null) {
  }

  constructor( value: INocoProductEvaluation2 | null ) {
    super( value );
    if ( value ) {
      this.value = value;
    }
  }
}

export class NocoProductEvaluation2Set {

  private static readonly tableName: string = 'product_evaluation_2';

  private _log: ILogger = LoggerFactory.build( 'NocoProductEvaluation2Set' );

  values: NocoProductEvaluation2[] = [];
  valuesById: {[id: number]: NocoProductEvaluation2} = {};

  toPageDefinitions(section: NocoEvaluationSection, questions: AppQuestionSet ): IAppPageDefinition[] {

    const pages: IAppPageDefinition[] = [];
    const sectionQuestions: NocoProductEvaluation2[] = this.values.filter( (e) => {
      if( section.value.Id === e.value.EvaluationSectionId ) {
        return true;
      }
      return false;
    } );


    for( const sectionQuestion of sectionQuestions ) {
      const pageIndex = sectionQuestion.value.EvaluationPage;
      let pageQuestions: IAppPageDefinition = pages[pageIndex-1];
      if( !pageQuestions) {
        pageQuestions = {
          title: section.value.Title,
          questionKeys: [],
        };

        pages[pageIndex-1] = pageQuestions;
      }

      // hacky
      if( 1 === sectionQuestion.value.QuestionNo ) {

        if( sectionQuestion.value.DependencyJson ) {

          const nocoDescriptor: INocoDependantDescriptor = JSON.parse( sectionQuestion.value.DependencyJson );
          pageQuestions.dependantDescriptor = NocoDependantDescriptor.toDependantDescriptor( nocoDescriptor );
        }

        if( sectionQuestion.value.PageTitle ) {
          pageQuestions.title = sectionQuestion.value.PageTitle;
        }
      }

      const question: AppQuestion = questions.getQuestionByNocoDbId( sectionQuestion.value.QuestionId );
      if( !question ) {
        this._log.warn( '!question', 'sectionQuestion.value.QuestionId', sectionQuestion.value.QuestionId );
      } else {
        pageQuestions.questionKeys[sectionQuestion.value.QuestionNo-1] = question.value.key;
      }
    }

    const answer: IAppPageDefinition[] = [];
    for( let i = 0; i < pages.length; i++ ) {
      const page = pages[i];
      if( !page ) {
        this._log.error( '!page', 'section', section, 'i', i );
        continue;
      }
      answer.push( page );
    }
    return answer;
  }

  public add( reference: NocoProductEvaluation2 ) {
    this.values.push( reference );
    this.valuesById[reference.value.Id] = reference;
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoProductEvaluation2>> {
    return proxy.getView<INocoProductEvaluation2>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoProductEvaluation2Set> {
    const value = await NocoProductEvaluation2Set.getValue( proxy );
    return new NocoProductEvaluation2Set(value);
  }

  public constructor( public value: IListingReponse<INocoProductEvaluation2> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoProductEvaluation2( rowValue );
      this.add( reference );
    }
  }

}

