import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {PublicProxy} from "../../../firebase/functions/PublicProxy";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "../../component.alert-dialog/alert-dialog";
import {RecaptchaVerifier,getAuth} from 'firebase/auth';
import {Auth} from "@firebase/auth";
import firebase from "firebase/compat";
import ConfirmationResult = firebase.auth.ConfirmationResult;

/**
 * Generated class for the SignInHotelComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'authenticate-phone',
  templateUrl: 'authenticate-phone.component.html',
  styleUrls: ['authenticate-phone.component.scss'],
  standalone: true,
})
export class AuthenticatePhoneComponent implements OnInit, AfterViewInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticatePhoneComponent' );

  private _publicProxy: PublicProxy = null;

  private _recaptchaVerifier: RecaptchaVerifier = null;
  private _confirmationResult: ConfirmationResult = null;

  @ViewChild('recaptchaVerifier') recaptchaVerifierElement;


  hide = true;

  phoneNumberFormControls = {
    phoneNumber: new FormControl(null, [ Validators.required ] )
  };

  phoneNumberFormGroup = new FormGroup({
    phoneNumber: this.phoneNumberFormControls.phoneNumber,
  });

  public phoneNumberError: string = null;


  authCodeFormControls = {
    authCode: new FormControl(null, [ Validators.required ] )
  };

  authCodeFormGroup = new FormGroup({
    authCode: this.authCodeFormControls.authCode,
  });

  public authCodeError: string = null;


  @Output() signInCompleted = new EventEmitter<void>();

  private _saveEmail( emailAddress: string ) {

    window.localStorage.setItem('AuthenticatePhoneComponent.emailAddress', emailAddress);
  }

  public static loadEmail(): string|null {

    return window.localStorage.getItem('AuthenticatePhoneComponent.emailAddress');
  }


  private _getPhoneControlError(): string|null {

    if( this.phoneNumberFormControls.phoneNumber.hasError( 'required')) {

      return 'Phone number required';
    }

    return null;
  }


  public async signInWithPhoneNumber() {


    this.authCodeFormControls.authCode.setValue('');

    const phoneNumber = this.phoneNumberFormControls.phoneNumber.value;
    this._log.debug( 'phoneNumber', phoneNumber );


    try {

      this._confirmationResult = await this.afAuth.signInWithPhoneNumber( phoneNumber, this._recaptchaVerifier );

      {
        const title = 'SMS code sent';
        const message = "You should shortly receive a code";

        const dialog = AlertDialogComponent.show( this.dialog, title, message );

        dialog.afterClosed().subscribe(result => {

          this._log.debug('dialog was closed', 'result', result );
        });
      }


    } catch (e) {

      this._log.error( 'e', e );
      const authError = e as IFirebaseError;
      this.phoneNumberError = authError.code;
    }

  }



  async onPhoneNumberFormSubmit() {


    this.phoneNumberError = this._getPhoneControlError();

    if( this.phoneNumberError ) {

      return;
    }

    const auth: Auth = getAuth();

    const self = this;

    this._confirmationResult = null;
    this._recaptchaVerifier = new RecaptchaVerifier(
      this.recaptchaVerifierElement.nativeElement,
      {

        'size': 'invisible',
        'callback': () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          self.signInWithPhoneNumber();
        },
        'expired-callback': () => {

          this._log.warn( 'expired-callback' );
        }
      },
      auth
    );

    // [Firebase Invisible ReCaptcha callback not being fired · Issue #3314 · firebase/firebase-js-sdk · GitHub](https://github.com/firebase/firebase-js-sdk/issues/3314)
    this._recaptchaVerifier.verify();
  }


  async onAuthCodeFormSubmit() {

    if( null === this._confirmationResult ) {

      this._log.error( 'null === this._confirmationResult' );
      return;
    }

    this.authCodeError = null;

    if( this.authCodeFormControls.authCode.hasError( 'required')) {

      this.authCodeError = 'Auth code is required';
      return;
    }


    const signInError = await this.sessionContext.signInWithPhone( this._confirmationResult, this.authCodeFormControls.authCode.value );

    if( signInError ) {

      // this._publicProxy.signInFailed( email );

      this._log.logError( 'Login failed', (event) => {
        event.addContext( 'this.phoneNumberFormControls.phoneNumber.value', this.phoneNumberFormControls.phoneNumber.value );
        event.addContext( 'signInError.code', signInError.code );
        event.addContext( 'signInError.message', signInError.message );
      })

      // this._log.error( 'signInError', signInError );
      this.authCodeError = "Login failed: Check authcode";

    } else {

      this.signInCompleted.emit();
    }

  }

  ngAfterViewInit(): void {


    this._log.debug( 'ngAfterViewInit');
  }


  ngOnInit(): void {

    this._log.debug( 'ngOnInit');
    // this.phoneNumberFormControls.phoneNumber.setValue( "+353871656827");
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               http: HttpClient,
               public dialog: MatDialog) {

    this._publicProxy = new PublicProxy( http );

  }




}
