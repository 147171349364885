import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {DialogData, QuestionBooleanDialogComponent} from "./question-boolean-dialog/question-boolean-dialog";
import {MatDialog} from "@angular/material/dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {BaseQuestion} from "../BaseQuestion";

@Component({
  selector: 'question-boolean',
  templateUrl: 'question-boolean.html',
  styleUrls: ['question-boolean.scss']
})

export class QuestionBooleanComponent extends BaseQuestion implements OnInit, AfterViewInit, OnDestroy {

  _log = LoggerFactory.build( 'QuestionBooleanComponent' );

  @Input() trueLabel: string = 'Yes';
  @Input() falseLabel: string = 'No';

  @Input() set disabled(value: boolean) {
    const previouslyDisabled = this.disabled;
    super.disabled = value;
    if( this.disabled ) {
      this.answer.value.value = null;
    } else if( previouslyDisabled ) {
      this.answer.value.value = false;
    }
  }

  @Output() change = new EventEmitter<QuestionBooleanComponent>();

  onOpenDialog(): void {
    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionBooleanDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionBooleanDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this._log.debug('dialog was closed');
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.validateType(AppQuestion.TYPE_BOOLEAN);

    if( this.answer.dependant ) {
      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {
        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        this.disabled = !isTruthy;
      } );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
  }

  getAnswerLabel() : string|null {
    if (this.answer.value === null || !this.answer.value.hasAnswer) {
      return;
    }
    return this.answer.value.value ? this.trueLabel : this.falseLabel;
  }

  constructor( public dialog: MatDialog ) {
    super();
  }
}
