import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {DocumentReference} from "../service.session-context/DocumentReference";
import {AppRouteManifest} from "../app/AppRouteManifest";


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  onViewDocument( doc: DocumentReference ) {

    const newWindow = window.open(doc.storageUrl, '_blank', 'noopener noreferrer');
    newWindow.opener=null;
  }

  onDownloadDocument( doc: DocumentReference ) {


    // vvv [javascript - Download data URL file - Stack Overflow](https://stackoverflow.com/questions/3916191/download-data-url-file)

    const link = document.createElement("a");

    link.download = doc.filename;
    link.href = doc.storageUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    // document.body.removeChild(link);

    // ^^^ [javascript - Download data URL file - Stack Overflow](https://stackoverflow.com/questions/3916191/download-data-url-file)

  }

  async init() {
  }



  onGoHome() {

    AppRouteManifest.HOME.navigate( this.router )
  }

  ngOnInit() {

    this.init();
  }

  constructor( public router: Router,
               public route: ActivatedRoute,
               public sessionContext: SessionContextProvider,
  ) {}

}
