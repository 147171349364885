import {Component, Input} from "@angular/core";
import {MatSidenav} from "@angular/material/sidenav";

@Component({
  selector: 'app-header-toolbar',
  styleUrls: ['component.header-toolbar.scss'],
  templateUrl: 'component.header-toolbar.html',
})

export class HeaderToolbarComponent {
  @Input() sidenav: MatSidenav;
}
