import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {BaseQuestion} from "../BaseQuestion";

@Component({
  selector: 'question-text',
  templateUrl: 'question-text.html',
  styleUrls: ['question-text.scss']
})

export class QuestionTextComponent extends BaseQuestion implements OnInit, OnDestroy {

  @Input() placeholder: string = "Enter text ...";

  @Input() set disabled(value: boolean) {
    super.disabled = value;
    if (this.disabled) {
      this.questionAnswer.answer.value.value = "";
      this.questionAnswer.answer.value.hasAnswer = false;
    }
  }

  get questionAnswer() {
    return this._questionAnswer;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.validateType(AppQuestion.TYPE_TEXT);

    if( this.questionAnswer.answer.dependant ) {
      this._dependantSubscription = this.questionAnswer.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {
        const isTruthy = dependant.isTruthy(this.questionAnswer.question.value.dependant);
        this.disabled = !isTruthy;
      } );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  constructor() {
    super();
  }
}
