import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {IStorage} from "./interface/IStorage";

export class LocalStore implements IStorage {

  private _log: ILogger = LoggerFactory.build( 'LocalStore' );

  setItem( name: string, value: string ) : void {
    try {
      return window.localStorage.setItem( name, value );
    } catch ( e ) {
      this._log.error( `'window.localStorage.setItem' call failed`, e );
    }
  }

  getItem( name: string ): string|null {
    try {
      return window.localStorage.getItem( name );
    } catch ( e ) {
      this._log.error( `'window.localStorage.getItem' call failed`, e );
      return null;
    }
  }

  removeItem( name: string ) : void {
    try {
      return window.localStorage.removeItem(name);
    } catch (e) {
      this._log.error(`'window.localStorage.removeItem' call failed`, e);
      return null;
    }
  }
}
