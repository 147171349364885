import {Component, Input} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {HelpDialog} from "./component.help-dialog/help-dialog";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";

@Component({
  selector: 'help',
  templateUrl: 'help.html',
  styleUrls: ['help.scss']
})
export class HelpComponent {

  @Input() answer: AppAnswer;

  onShowHelp() {
    HelpDialog.open( this.dialog, this.answer );
  }

  constructor( public dialog: MatDialog) {
  }

}
