import firebase from 'firebase/compat/app';
import database = firebase.database;
import {IFirebaseConnection} from "../../FirebaseConnection";
import {AppClient, IAppClient} from "../../../model/AppClient";

export class FirebaseClient {
  private static getPath( clientKey: string ) {
    return `/clients/${clientKey}`;
  }

  static async readAll(firebaseConnection: IFirebaseConnection ): Promise<AppClient[]> {

    const values: { [clientKey: string]: IAppClient; } = await firebaseConnection.database2.getValue( '/clients' );

    const answer: AppClient[] = [];

    for( const clientKey of Object.keys(values) ) {
      answer.push( new AppClient( values[clientKey], clientKey ));
    }

    return answer;
  }

  static async readReference( firebase: IFirebaseConnection, clientKey: string ): Promise<AppClient|null> {
    const path = this.getPath( clientKey );
    const value: IAppClient = await firebase.database2.getValue<IAppClient>( path );

    if ( value ) {
      return new AppClient(value, clientKey);
    }

    return null;
  }

  static async writeReference( fbDb: database.Database, client: AppClient  ): Promise<void> {
    const path = this.getPath( client.clientKey );
    return fbDb.ref( path ).set( client.value );
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, clientKey: string, value: IAppClient ): Promise<void> {
    const path = this.getPath(clientKey);
    return firebaseConnection.database2.setValue(path, value);
  }
}
