import {ValueObject} from "./ValueObject";
import {ISearchable} from "../../common/interfaces/ISearchable";

export interface IAppMaintenanceMetaData {
  version?: number[];
}

export interface IAppMaintenance {
  _meta?: IAppMaintenanceMetaData;
  key: string;
  detail: string;
  start: string;
  end: string;
}

export class AppMaintenance extends ValueObject<IAppMaintenance> implements ISearchable {
  protected onSetValue(value: IAppMaintenance | null) {
  }

  startDate : Date;
  endDate: Date;

  constructor(value: IAppMaintenance|null = null,
              public key: string|null = null) {
    super(value);
    try {
      this.startDate = new Date(value.start);
      this.endDate = new Date(value.end);
    } catch (e) {

    }
  }

  get searchableString(): string {
     return this?.value?.detail;
  }

  public static array = {
    sortByEndDateDesc: ( maintenances: AppMaintenance[] ) => {
      maintenances.sort( (a,b) => {
        const aTime = new Date(a.value.end).getTime();
        const bTime = new Date(b.value.end).getTime();
        return bTime - aTime;
      })
    }
  }
}
