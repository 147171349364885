<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">
    <app-side-menu [sidenav]="sidenav"></app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>

    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">
        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <a class="mobility-mojo-logo" [routerLink]="['/home']">
            <img src="assets/mobility-mojo/logo-and-text.white.png"
                 aclass="header-toolbar-logo">
          </a>
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div aclass="page-content">

        <app-session-checker *ngIf="!sessionContext.sessionIsReady"></app-session-checker>

        <div *ngIf="sessionContext.sessionIsReady && !sessionContext.isAdministrator && propertyService.hasNoProperties">

          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex cclass="instructions">
                You have no properties associated with your account.
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">
              <div fxFlex cclass="instructions">
                If you believe this is a mistake, please contact the <strong>Mobility Mojo</strong> staff.
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="sessionContext.sessionIsReady && this.propertyService.propertyContext">

          <div fxLayout="column" fxLayoutGap="10px">
<!--            <div fxLayout="row" fxLayoutAlign="start center">-->
<!--              <div fxFlex cclass="instructions">-->
<!--                <strong>-->
<!--                  To complete your audit, click each section and answer the questions.-->
<!--                </strong>-->
<!--              </div>-->
<!--            </div>-->
            <mat-card cclass="property-details">
              <property-details [property]="this.propertyService.propertyContext.property"></property-details>
              <span style="display: none">
                    propertyKey: {{this.propertyService.propertyContext.property.propertyKey}}
              </span>
            </mat-card>
          </div>

          <div *ngIf="sessionContext.sessionIsReady && initCompleted">
          <div *ngFor="let child of hierarchyRoot.children; let $index = index" class="cluster-container">

            <app-cluster [childCluster]="child.cluster"
                         [clusterNode]="child"
                         (includeCluster)="onIncludeCluster(child, $event)"
                         (deleteCluster)="onDeleteCluster(child, $event )"
                         (addCluster)="onAddSubSection(child)"
                         [sectionIndex]="$index+1"
                         [evaluationState]="evaluationState"
                         [productType]="productType"
            >
            </app-cluster>

          </div>


            <div style=" margin-bottom: 16px;">

              <mat-card style="margin-bottom: 16px;">

                <mat-card-content>

                  <button cclass="add-section" mat-raised-button
                          *ngIf="canEditClusters"
                          (click)="onAddSection()"
                          [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
                  >
                    <div cclass="add-section sub-section add-sub-section">
                      <mat-icon cclass="add-section-icon" aria-hidden="false" aria-label="Add">add</mat-icon>
                      <span cclass="add-section-title">Add Section</span>
                      <span cclass="add-section-filler"></span>
                    </div>
                  </button>

                  <div>
                    <button cclass="add-section" mat-raised-button style="width:100%"
                            (click)="onEvaluationCompleted()"
                            [disabled]="!readyToSubmit()"
                    >
                      <div cclass="add-section sub-section submit">
                        <mat-icon cclass="add-section-icon" aria-hidden="false" aria-label="Add" style="transform:translateY(6px);">send</mat-icon>
                        <span cclass="add-section-title">{{submitButtonLabel()}}</span>
                      </div>
                    </button>

                  </div>

                </mat-card-content>

              </mat-card>

            </div>

          </div>

        </div>
        <ng-fireworks #fireworks="ngFireworks" [options]="options" class="fireworks" *ngIf="fireworksEnabled"></ng-fireworks>
      </div>

      <mat-toolbar aclass="footer-toolbar">
        <mat-toolbar-row>
        </mat-toolbar-row>
      </mat-toolbar>

    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
