<div *ngIf="displayMessage" cclass="maintenance-banner">
    <mat-icon aria-label="warning">warning</mat-icon><div>

    <div cclass="maintenance-header">Maintenance Warning</div>

    <div cclass="maintenance-message">
      {{message}}

      <div *ngIf="detail" cclass="maintenance-details">
        {{detail}}
      </div>

    </div>

    <div cclass="maintenance-button">
      <button mat-raised-button type="button" (click)="onOkClick()">Ok</button>
    </div>
</div>
