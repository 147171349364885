import {Component, OnInit} from '@angular/core';
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";
import {IScore} from "../javascript.lib.mojo-base/model/score/IScore";
import {PropertyService} from "../service.property/property-service";

@Component({
  selector: 'page-completed',
  templateUrl: 'completed.html',
  styleUrls: ['completed.scss']
})
export class CompletedPage implements OnInit {

  public score: IScore = {
    numerator: 0,
    denominator: 100,
  };

  showCongratulationsText = false;

  ngOnInit(): void {
    this.score = this.propertyService.propertyContext.getScore();
    this.propertyService.propertyContext.completedPageHasBeenShown = true;
  }

  onHome() {
    AppRouteManifest.HOME.navigate( this.router )
  }

  constructor( public router: Router,
               public propertyService: PropertyService,
  ) {

    this.showCongratulationsText = propertyService.propertyContext.showCongratulationsText;
    propertyService.propertyContext.showCongratulationsText = false;
  }
}
