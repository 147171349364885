import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {IStorage} from "./interface/IStorage";

export class SessionStorageWrapper implements IStorage {

  private _log: ILogger = LoggerFactory.build( 'SessionStorageWrapper' );

  static readonly CHOSEN_PROPERTY_KEY = 'chosenPropertyKey';
  static readonly CLIENT_KEY = 'clientKey';
  static readonly INSTUCTIONS = 'instructions';

  public setItem(prefix: string|null, name: string, value: string) : void {
    try {
      name = SessionStorageWrapper.getName(prefix, name);
      return window.sessionStorage.setItem(name, value);
    } catch ( e ) {
      this._log.error(`'window.sessionStorage.setItem' call failed`, e);
    }
  }

  public getItem(prefix: string|null, name: string) : string|null {
    try {
      name = SessionStorageWrapper.getName(prefix, name);
      return window.sessionStorage.getItem(name);
    } catch ( e ) {
      this._log.error(`'window.sessionStorage.getItem' call failed`, e);
      return null;
    }
  }

  public removeItem(prefix: string|null, name: string) : void {
    try {
      name = SessionStorageWrapper.getName(prefix, name);
      return window.sessionStorage.removeItem(name);
    } catch (e) {
      this._log.error(`'window.sessionStorage.removeItem' call failed`, e);
      return null;
    }
  }

  public clear() : void {
    try {
      return window.sessionStorage.clear();
    } catch (e) {
      this._log.error(`'window.sessionStorage.clear' call failed`, e);
      return null;
    }
  }

  private static getName(prefix: string, name: string) : string {
    return (prefix)
      ? `${prefix}_${name}`
      : name;
  }
}
