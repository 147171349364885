import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from 'firebase/compat/app';
import User = firebase.User;
import {AppEvaluationUser} from "../javascript.lib.mojo-base/model/AppEvaluationUser";
import {
  BaseSessionContext,
  SessionContextState
} from "../common/service.session-context/BaseSessionContext";
import {IFirebaseError} from "../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {DocumentReference} from "./DocumentReference";
import {
  getStorage,
  ref,
  listAll
} from "firebase/storage";
import {StorageReference} from "@firebase/storage";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {FirebaseConnectionService} from "../common/service.firebase-connection/FirebaseConnectionService";
import ConfirmationResult = firebase.auth.ConfirmationResult;
import {Router} from "@angular/router";
import {FirebaseRoles} from "../javascript.lib.mojo-base/firebase/FirebaseRoles";
import {UserService} from "../service.user/user-service";
import {SessionStorageWrapper} from "../common/util/SessionStorageWrapper";
import {environment} from "../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class SessionContextProvider extends BaseSessionContext {

  public documents: DocumentReference[] = [];
  public id:string;

  public async reloadCurrentUser() : Promise<AppEvaluationUser> {

    this.user = await this.userService.getAppEvaluationUser(this._fbUser.uid, true);
    this._sessionStorage.setItem(this.user.userUid, "temporaryPropertyKey", this.user.value.temporaryPropertyKey)

    return this.user;
  }

  private async initReferences(user: User) : Promise<AppEvaluationUser> {

    this.state = SessionContextState.UserDataLoading;

    try {
      this.user = await this.userService.getAppEvaluationUser(user.uid);
    } catch (e) {
      this._log.error("User not found in Firebase users", "uid", user.uid, e);
      this.state = SessionContextState.Error;
      return;
    }

    this._clientKey = this.user.clientKey;

    await this.initDocuments(user);

    return this.user;
  }

  private async initDocuments(user: User) : Promise<void> {
    const storage = getStorage();
    const storageRef: StorageReference = ref(storage);
    const path = `documents/${user.uid}/`;
    this._log.info( 'path', path );
    const documentsRef = ref( storageRef, path );
    const listing = await listAll(documentsRef);
    this._log.info( 'listing.items.length', listing.items.length );

    const documents: DocumentReference[] = [];

    for( const item of listing.items ) {
      const document = new DocumentReference( item.fullPath );
      await document.resolveStorageUrl( storageRef );
      documents.push( document );
    }

    this.documents = documents;
  }

  async signInWithEmailAndPassword(email: string, password: string ): Promise<IFirebaseError|null> {
    return await this.doSignIn(() => this._auth.signInWithEmailAndPassword(email, password));
  }

  async signInWithEmailLink(email: string, emailLink: string ): Promise<IFirebaseError|null> {
    return await this.doSignIn(() => this._auth.signInWithEmailLink(email, emailLink));
  }

  async signInWithPhone(confirmationResult: ConfirmationResult, phoneAuthCode: string): Promise<IFirebaseError|null> {
    return await this.doSignIn(() => confirmationResult.confirm(phoneAuthCode));
  }

  private async doSignIn(signIn: any): Promise<IFirebaseError|null> {
    try {
      const userCredential: firebase.auth.UserCredential = await signIn();
      await this.init(userCredential.user);
      return;
    } catch (e) {
      this._log.warn('login', e );
      return e as IFirebaseError;
    }
  }

  public async sendSignInLinkToEmail( email: string ): Promise<IFirebaseError|null> {

    const actionCodeSettings = {
      url: `https://localhost:10000/finishSignUp`,
      handleCodeInApp: true,
    };

    this._log.debug( 'actionCodeSettings.url', actionCodeSettings.url );
    this._log.debug( 'actionCodeSettings', actionCodeSettings );
    this._log.debug( 'email', email );

    try {

      await this._auth.sendSignInLinkToEmail( email, actionCodeSettings );
      return null; // no error

    } catch (e) {

      this._log.error( 'e', e );
      return e as IFirebaseError;

    }

    // ^^^ https://firebase.google.com/docs/auth/web/email-link-auth
  }

  public set userHasSeenInstructions(value: string) {
    this._sessionStorage.setItem(this.user.userUid, SessionStorageWrapper.INSTUCTIONS, value);
  }

  public get userHasSeenInstructions() : string {
    return this._sessionStorage.getItem(this.user.userUid, SessionStorageWrapper.INSTUCTIONS);
  }

  async signOut() : Promise<void> {
    this._log.info("signOut", "this.user.userUid", this.user?.userUid);
    this.documents = null;
    await this.userService.clearTemporaryKey(this);
    await super.signOut();
    this._log.info("signOut", "this.user.userUid", this.user?.userUid, "this._fbUser", this._fbUser);
  }

  private async init(user: User|null) : Promise<AppEvaluationUser> {
    if (!user) {
      this.state = SessionContextState.LoggedOut;
      this.user = null;
      return;
    }

    this.state = SessionContextState.UserIsAuthenticated;
    this._fbUser = user;
    this._isAdministrator = false;

    this.settingUp = true;

    try {
      await this.initReferences(user);
      const idTokenResult: firebase.auth.IdTokenResult = await user.getIdTokenResult();
      this.state = SessionContextState.UserDataLoaded;
      if(idTokenResult.claims[FirebaseRoles.ROLE_ADMINISTRATOR]) {
        this._isAdministrator = true;
        this._clientKey = this._sessionStorage.getItem(this.user.userUid, SessionStorageWrapper.CLIENT_KEY) ?? environment.defaultClientKey;
        this.clientKeySubject.next(this._clientKey);
      }
    } finally {
      this.settingUp = false;
    }

    this.state = SessionContextState.UserIsReady;

    return this.user;
  }

  constructor( public firebaseConnection: FirebaseConnectionService,
               public router: Router,
               public userService: UserService,
               auth: AngularFireAuth,
               sessionStorage: SessionStorageWrapper
               ) {

    super(auth, LoggerFactory.build( 'SessionContextProvider'), sessionStorage);

    this._auth.onAuthStateChanged( async (user: firebase.User|null) => {
      return this.init(user);
    });
  }
}
