import {Component, OnInit} from '@angular/core';
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {ActivatedRoute, Router} from "@angular/router";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../javascript.lib.mojo-base/log/Logger";
import {ProductTypeOptions} from "../javascript.lib.mojo-base/model/ProductType";
import {Subscriptions2} from "../javascript.lib.mojo-base/util/Subscriptions2";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {PropertyService} from "../service.property/property-service";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {AppStorage} from "../app/AppStorage";
import {AuditService} from "../service.audit/audit-service";
import {AppAudit, EAppAuditState} from "../javascript.lib.mojo-base/model/AppAudit";

@Component({
  selector: 'page-select-property',
  templateUrl: 'select-property.html',
  styleUrls: ['select-property.scss']
})

export class SelectPropertyPage implements OnInit {

  private _log:ILogger = LoggerFactory.build( 'SelectPropertyPage' );

  public subscriptions = new Subscriptions2();
  public properties: AppProperty[];
  public filteredProperties: AppProperty[];
  public currentPropertyKey: string;
  public audits: AppAudit[];


  public onFilteredPropertiesChange(filteredProperties: AppProperty[]) : void {
    this.filteredProperties = filteredProperties;
  }

  async onSelectProperty( property: AppProperty ) : Promise<void> {
    if (property) {
      this._log.info("onSelectProperty", "propertyKey", property.propertyKey);
      if (this.sessionContext.user.hasPropertyKey(property.propertyKey)) {
        AppRouteManifest.AUDIT.navigate(this.router, {"propertyKey": property.propertyKey});
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.properties = await this.propertyService.getPropertiesForCurrentUser();
    this.filteredProperties = this.properties;

    const propertyKeys = this.properties.map(p => p.propertyKey);
    this.audits = await this.auditService.getAppAudits(propertyKeys);
  }

  public getStateLabel(propertyKey: string) : string {
    const state = this.audits.find((a) => a.propertyKey === propertyKey)?.value.state ?? EAppAuditState.unknown;
    switch (state) {
      case EAppAuditState.unknown:
      case EAppAuditState.created:
        return "Ready to start";
      case EAppAuditState.inProgress:
        return "In Progress";
      case EAppAuditState.complete:
        return "Ready to submit";
      case EAppAuditState.submitted:
      case EAppAuditState.reportInProgress:
      case EAppAuditState.reportSent:
        return "Submitted";
    }
  }

  constructor( public afDb: AngularFireDatabase,
               public router: Router,
               public route: ActivatedRoute,
               public sessionContext: SessionContextProvider,
               private propertyService: PropertyService,
               private auditService: AuditService) {
    this.subscriptions.subscribe(this.sessionContext.stateSubject, (state : SessionContextState) => {
      if (state === SessionContextState.UserIsReady) {
        this.currentPropertyKey = AppStorage.getPropertyKey(this.sessionContext.user.userUid);
      }
    });
  }

  protected readonly ProductTypeOptions = ProductTypeOptions;
}
