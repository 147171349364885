import {Component, EventEmitter, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {BaseSessionContext, SessionContextState} from "../service.session-context/BaseSessionContext";
import {AppRouteManifest} from "../../app/AppRouteManifest";
import {Router} from "@angular/router";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SignInComponent} from "../module.base-components/component.sign-in/sign-in";
import {NgIf} from "@angular/common";
import {Subscriptions2} from "../../javascript.lib.mojo-base/util/Subscriptions2";

@Component({
  selector: 'app-session-checker',
  styleUrls: ['session-checker.component.scss'],
  templateUrl: './session-checker.component.html',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    SignInComponent,
    NgIf
  ]
})
export class SessionCheckerComponent implements OnInit, OnDestroy {

  private _log = LoggerFactory.build( 'SessionCheckerComponent' );

  @Output() sessionIsReady = new EventEmitter<boolean>();

  private _subscriptions: Subscriptions2 = new Subscriptions2();

  public userIsReady = false;
  public userIsLoggedOut = true;
  public intermediaStatus = "Loading data ...";

  onPasswordResetRequested( emailAddress: string ) {
    AppRouteManifest.PASSWORD_RESET.navigateToPage( this.router, emailAddress );
  }

  ngOnInit() {
    this._subscriptions.subscribe(this.sessionContext.stateSubject, (next => {
      this.userIsReady = false;
      this.userIsLoggedOut = false;

      if( next === SessionContextState.UserIsAuthenticating ) {
        this.intermediaStatus = "Checking user login ...";
      } else if( next === SessionContextState.UserDataLoading ) {
        this.intermediaStatus = "Loading user data ...";
      } else if( next === SessionContextState.UserIsReady ) {
        this.userIsReady = true;
        this.intermediaStatus = "User is ready";
        this.sessionIsReady.emit(true);
      } else if( next === SessionContextState.LoggedOut ) {
        this.userIsLoggedOut = true;
        this.intermediaStatus = "User is logged out";
      }
    }));
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  constructor(public router: Router,
              public sessionContext: BaseSessionContext) {
  }
}
