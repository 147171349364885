import firebase from 'firebase/compat/app';
import {AppEvaluationUser, IAppEvaluationUser, IAppEvaluationUser2} from "../../../model/AppEvaluationUser";
import {IFirebaseConnection} from "../../FirebaseConnection";
import {LoggerFactory} from "../../../log/LoggerFactory";
import database = firebase.database;
import {IRealtimeDatabase} from "../../RealtimeDatabase";

export class FirebaseEvaluationUser {

  private static log = LoggerFactory.build( 'FirebaseEvaluationUser' );

  private static getPath( userUid: string ) {

    return `/users/evaluation/${userUid}`;
  }


  static async readAllUsers( fbDb: database.Database ): Promise<{ [userUid: string]: IAppEvaluationUser; }> {
    const path = `/users/evaluation/`;
    const snapshot = await fbDb.ref( path ).once( 'value' )
    const answer: { [userUid: string]: IAppEvaluationUser; } = snapshot.val();
    return answer;
  }

  private static ToArray(value: { [p: string]: IAppEvaluationUser }) : AppEvaluationUser[] {
    const appEvaluationUsers: AppEvaluationUser[] = [];
    for( const uuid of Object.keys(value)) {
      appEvaluationUsers.push( AppEvaluationUser.build( value[uuid], uuid ));
    }
    return appEvaluationUsers;
  }

  static async readAllReferences( firebaseConnection: IFirebaseConnection): Promise<AppEvaluationUser[]> {
    const path = `/users/evaluation/`;
    const snapshotValue: { [userUid: string]: IAppEvaluationUser; } = await firebaseConnection.database2.getValue<{ [userUid: string]: IAppEvaluationUser; }>( path );
    return this.ToArray(snapshotValue);
  }

    static async readReference2( firebaseConnection: IFirebaseConnection, userUid: string ): Promise<AppEvaluationUser|null> {

      FirebaseEvaluationUser.log.debug( 'userUid', userUid);

    const path = FirebaseEvaluationUser.getPath( userUid );
    const snapshotValue = await firebaseConnection.database2.getValue<IAppEvaluationUser>( path );

    FirebaseEvaluationUser.log.debug( 'snapshotValue', snapshotValue);
    if ( snapshotValue ) {
      return AppEvaluationUser.build(snapshotValue, userUid);
    }
    return null;
  }

  static async readReference(fbDb: database.Database, userUid: string ): Promise<AppEvaluationUser|null> {

    const path = FirebaseEvaluationUser.getPath( userUid );
    const snapshot = await fbDb.ref( path ).once( 'value' );
    const value: IAppEvaluationUser = snapshot.val();

    FirebaseEvaluationUser.log.debug( 'value', value);
    if ( value ) {
      return AppEvaluationUser.build(value, userUid);
    }
    return null;
  }

  static async writeReference( firebaseConnection: IFirebaseConnection, facilityUser: AppEvaluationUser ): Promise<void> {
    return FirebaseEvaluationUser.writeValue( firebaseConnection, facilityUser.userUid, facilityUser.value  );
  }

  static async writeValue( firebaseConnection: IFirebaseConnection, userUid: string, value: IAppEvaluationUser|IAppEvaluationUser2 ): Promise<void> {
    const path = this.getPath( userUid );
    return firebaseConnection.database2.setValue( path, value );
  }
}


