import { HttpClient } from "@angular/common/http";
import {BaseProxy } from "./BaseProxy";
import {environment} from "../../../environments/environment";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {IEvaluationState} from "../../../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {
  IEvaluationCompletedRequest
} from "../../../javascript.lib.mojo-base/firebase/functions/authenticated/EvaluationCompletedRequest";
import {IProxyResponse} from "../../../javascript.lib.mojo-base/firebase/functions/ProxyResponse";
import {
  IEvaluationStartedRequest
} from "../../../javascript.lib.mojo-base/firebase/functions/authenticated/EvaluationStartedRequest";




export class AuthenticatedProxy {

  private _log = LoggerFactory.build( 'AuthenticatedProxy' );

  private _proxy: BaseProxy;


  public async ping() {

    const options = await this._proxy.getAuthorizedOptions( this.idToken, BaseProxy.CONTENT_TYPE_APPLICATION_OCTET_JSON );

    const postUrl = `${environment.cloudFunctionsUrl}/authenticated/ping`;
    this._log.debug( 'postUrl', postUrl );

    const request = {
    };

    await this._proxy.post( postUrl, request, options );
  }

  public async evaluationStarted(clientKey: string, productId: string, propertyId: string, userUid: string,
                                 userEmail: string|null, message: string|null): Promise<IProxyResponse<IEvaluationState>> {

    const options = await this._proxy.getAuthorizedOptions( this.idToken, BaseProxy.CONTENT_TYPE_APPLICATION_OCTET_JSON );

    const postUrl = `${environment.cloudFunctionsUrl}/authenticated/evaluationStarted`;
    this._log.debug( 'postUrl', postUrl );

    const request: IEvaluationStartedRequest = {
      clientKey,
      productId,
      propertyId,
      userUid,
      userEmail,
      message
    };

    return this._proxy.post<IEvaluationState>( postUrl, request, options );
  }


  public async evaluationCompleted(clientKey: string, productId: string, propertyId: string, userUid: string,
                                   userEmail: string|null, message: string|null): Promise<IProxyResponse<IEvaluationState>> {

    const options = await this._proxy.getAuthorizedOptions( this.idToken, BaseProxy.CONTENT_TYPE_APPLICATION_OCTET_JSON );

    const postUrl = `${environment.cloudFunctionsUrl}/authenticated/evaluationCompleted`;
    this._log.debug( 'postUrl', postUrl );

    const request: IEvaluationCompletedRequest = {
      clientKey,
      productId,
      propertyId,
      userUid,
      userEmail,
      message,
    };

    return this._proxy.post<IEvaluationState>( postUrl, request, options );
  }


  constructor( http: HttpClient, public idToken: string ) {
    this._proxy = new BaseProxy( http );
  }
}
