import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AppAnswer} from "../../../../javascript.lib.mojo-base/model/AppAnswer";
import {FirebaseBucketVersion9} from "../../../firebase/FirebaseBucketVersion9";
import {StorageVersion9} from "../../../firebase/StorageVersion9";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";

@Component({
  selector: 'help-dialog',
  templateUrl: 'help-dialog.html',
  styleUrls: ['help-dialog.scss']
})
export class HelpDialog implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'HelpDialog' );

  static pageParams = {
    answer: null,
  };

  answer: AppAnswer;
  helpText: string;
  helpImageFilename: string;
  cmMeasurementMaxValue: number = null;
  cmMeasurementMinValue: number = null;
  falseyDependentAnswer: AppAnswer = null;
  helpImagePaths: string[] = [];
  ready: boolean;

  dismiss() {
    this.dialogRef.close();
  }

  static open(dialog: MatDialog, answer: AppAnswer) {

    HelpDialog.pageParams.answer = answer;

    return dialog.open(HelpDialog, {
      data: {
        answer
      },
      panelClass: 'help-dialog'
    });
  }

   async ngOnInit() : Promise<void> {
     if (this.helpImageFilename) {
       const bucketImpl = new StorageVersion9().bucket() as FirebaseBucketVersion9;
       const helpImages = this.helpImageFilename.split(',');
       for (const helpImage of helpImages) {
         const imagePath = await bucketImpl.getDownloadURL(`/images/${helpImage.trim()}`);
         this.helpImagePaths.push(imagePath);
       }
     }
     this.ready = true;
   }

  constructor( public dialogRef: MatDialogRef<HelpDialog>) {
    this.answer = HelpDialog.pageParams.answer;
    const question = this.answer.question;

    this.helpText = question.value.helpText;

    {
      const dependant = this.answer.dependant;
      if (dependant) {
        if (!dependant.isTruthy(this.answer.question.value.dependant)) {
          this.falseyDependentAnswer = dependant;
        }
      }
    }

    if (question.isCmMeasurement) {
      if (question.value.type2 && question.value.type2.typeCmMeasurement) {

        const maxValue = question.value.type2.typeCmMeasurement.maxValue;
        if (null != maxValue && Number.MAX_VALUE !== maxValue) {
          this.cmMeasurementMaxValue = maxValue;
        }
        const minValue = question.value.type2.typeCmMeasurement.minValue;
        if (null != minValue) {
          this.cmMeasurementMinValue = minValue;
        }
      }
    }

    this.helpImageFilename = question.value.helpImageFilename;
  }
}
