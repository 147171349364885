import {AppAspectAnswers} from "../javascript.lib.mojo-base/model/app.aspect/AppAspectAnswers";
import {AppProperty} from "../javascript.lib.mojo-base/model/AppProperty";
import {IScore} from "../javascript.lib.mojo-base/model/score/IScore";
import {IProduct} from "../model.product/IProduct";
import {ProductHelper} from "../model.product/ProductHelper";
import {LoggerFactory} from "../javascript.lib.mojo-base/log/LoggerFactory";
import {EvaluationStatus} from "../javascript.lib.mojo-base/model/evaluation/EvaluationStatus";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {ProductBuilder} from "../service.product-builder/product-builder.service";

export class PropertyContext {

  private static log = LoggerFactory.build( 'PropertyContext' );

  public propertyKey: string;
  public aspectAnswers: AppAspectAnswers[] = [];

  public completedPageHasBeenShown: boolean = false;
  public showCongratulationsText: boolean = true;

  private _completed: boolean|null = null;
  private _score: IScore = null;

  public evaluationStatus: EvaluationStatus = new EvaluationStatus( null );

  // TODO: rewrite all of this!
  isEvaluationCompleted(): boolean|null {

    if( null !== this._completed ) {
      return this._completed;
    }

    let optionalSections = 0;
    let optionalSectionsCompleted = 0;
    for(const aspect of this.aspectAnswers) {
      if (aspect.isOptional()) {
        optionalSections++;
        if (aspect.isCompleted()) {
          optionalSectionsCompleted++;
        }
      } else if(!aspect.isCompleted()) {
        // a non-optional section is incomplete so the
        // evaluation is not complete
        this._completed = false;
        return this._completed;
      }
    }

    // all non-optional sections are complete, and if we have any optional sections at least one must be complete
    this._completed
      = (optionalSections > 0 && optionalSectionsCompleted > 0)
        ? true
        : false;
    return this._completed;
  }

  getScore(): IScore|null {

    if( this._score ) {
      return this._score;
    }

    if( !this.isEvaluationCompleted() ) {
      return null;
    }

    const score: IScore = {
      numerator: 0,
      denominator: 0
    }

    for( const aspect of this.aspectAnswers ) {

      const aspectScore = aspect.getScore();

      if( !aspectScore ) {
        continue;
        // return null;
      }

      score.numerator += aspectScore.numerator;
      score.denominator += aspectScore.denominator;
    }

    this._score = score;
    return this._score;
  }

  private static async _getProduct( property: AppProperty,
                              productBuilder: ProductBuilder ): Promise<IProduct> {
    const productTemplate = await productBuilder.build(property.value.productType);

    if( property.value.excludePhotos ) {
      PropertyContext.log.debug( 'property.value.excludePhotos' );
      return ProductHelper.cloneProduct( productTemplate, true );
    }
    return productTemplate;
  }

  public static async build( sessionContext: SessionContextProvider,
                             property: AppProperty,
                             productBuilder: ProductBuilder): Promise<PropertyContext> {
    const product : IProduct = await PropertyContext._getProduct( property, productBuilder );
    return new PropertyContext( property, product, sessionContext );
  }

  private constructor( public property: AppProperty,
                       public product: IProduct,
                       public sessionContext: SessionContextProvider,) {
    this.propertyKey = property.propertyKey;
  }
}
