import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {SessionContextProvider} from "../service.session-context/session-context-provider";
import {IMenuItem} from "../common/module.base-components/component.side-menu/side-menu.component";
import {AppRouteManifest} from "../app/AppRouteManifest";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SessionContextState} from "../common/service.session-context/BaseSessionContext";
import {ApplicationContextProvider} from "../service.application-context/application-context";
import {MatDialog} from "@angular/material/dialog";
import {MatIcons} from "../common/util/MatIcons";
import {BackOfficeRouteManifest} from "../module.back-office/BackOfficeRouteManifest";
import {ConfirmCancelDialogComponent} from "../common/module.base-components/component.confirm-cancel-dialog/confirm-cancel-dialog";
import {BaseSideMenuModel} from "../common/service.side-menu/BaseSideMenuModel";
import {PropertyService} from "../service.property/property-service";


@Injectable()
export class SideMenuProvider extends BaseSideMenuModel implements OnDestroy {

  private _sessionSateSubscription: Subscription;

  public async onGoToBackOffice(): Promise<void> {
    if (this.sessionContext.isAdministrator) {
      BackOfficeRouteManifest.BACK_OFFICE_HOME.navigate(this.router);
    }
  }

  public async onSelectProperty() {
    AppRouteManifest.SELECT_PROPERTY.navigate( this.router );
  }

  public onLogout( personalIdentifier: string) {
    const dialog = ConfirmCancelDialogComponent.open( this.dialog, {
      message: `You are logged in as '${personalIdentifier}'`,
      title: 'Logout?',
      okLabel: 'LOGOUT'
    });

    dialog.afterClosed().subscribe(result => {
      if( result ) {
        return this.doLogout();
      }
    });
  }

  private async doLogout() {
    await this.sessionContext.signOut();
    AppRouteManifest.HOME.navigate(this.router);
  }

  private getPropertyMenuItem(): IMenuItem {
    return {
      disabled: () => {
        return !this.sessionContext.isAuthenticated;
      },
      matIcon: MatIcons.icons.properties,
      label: () => {
        return 'Choose property'
      },
      onClick: async () => {
        return this.onSelectProperty();
      }
    };
  }

  private getBackOfficeMenuItem() : IMenuItem {
    return {disabled: () => {
        return !this.sessionContext.isAdministrator;
      },
      matIcon: MatIcons.icons.backOffice,
      label: () => {
        return 'Back Office'
      },
      onClick: async () => {
        return this.onGoToBackOffice();
      }
    };
  }

  private getLogoutMenuItem(): IMenuItem {
    return {
      disabled: () => {
        return !this.sessionContext.isAuthenticated;
      },
      matIcon: MatIcons.icons.logout,
      label: () => {
        if( this.sessionContext.isAuthenticated ) {
          return `Logout: ${this.sessionContext.username}`;
        }
        return 'Logout: -';
      },
      onClick: async () => {
        return this.onLogout(this.sessionContext.username);
      }
    };
  }

  ngOnDestroy(): void {
    if( null != this._sessionSateSubscription ) {
      this._sessionSateSubscription.unsubscribe();
      this._sessionSateSubscription = null;
    }
  }

  init(): void {
    this._sessionSateSubscription = this.sessionContext.stateSubject.subscribe( (next: SessionContextState) => {

      this.menuItems = [];
      if(SessionContextState.UserIsReady === next) {
        // future state - do this by role / specific rights
        if (this.sessionContext.isAdministrator) {
          this.menuItems = [
            this.getBackOfficeMenuItem(),
            this.buildNavLink(MatIcons.icons.maintenance, "Maintenance", "#/back-office/maintenance"),
            this.buildNavLink(MatIcons.icons.audit, "Audits", "#/back-office/audit"),
            this.buildNavLink(MatIcons.icons.clients, "Clients", "#/back-office/client"),
            this.buildNavLink(MatIcons.icons.properties, "Properties", "#/back-office/property"),
            this.buildNavLink(MatIcons.icons.user, "Users", "#/back-office/user"),
            this.buildNavLink(MatIcons.icons.report, "Reports", "#/back-office/report")
          ];
        } else {
          this.menuItems = [
            this.getHomeMenuItem(),
            this.getPropertyMenuItem(),
          ];
        }
        this.menuItems.push(this.getLogoutMenuItem())
      }

    });
  }

  constructor(sessionContext: SessionContextProvider,
              applicationContext: ApplicationContextProvider,
              router: Router,
              propertyService: PropertyService,
              private dialog: MatDialog ) {

    super(sessionContext, applicationContext, router, propertyService);

    this.init();
  }
}
