import {IFirebaseConnection} from "../../FirebaseConnection";
import {IListingReponse} from "../../../nocodb/ListingReponse";
import {ILogger} from "../../../log/Logger";
import {LoggerFactory} from "../../../log/LoggerFactory";

export class FirebaseNocoResponseCache {

  private static _log: ILogger = LoggerFactory.build( 'FirebaseNocoResponseCache' );

  private static getPath(table: string) {
    return `/nocoResponseCache/${table}`;
  }

  static async read<T>( firebase: IFirebaseConnection, table: string): Promise<IListingReponse<T>|null> {
    const path = this.getPath(table);

    let data : IListingReponse<T>;
    try {
      data = await firebase.database2.getValue<IListingReponse<T>>(path);
    } catch (e) {
      this._log.error("Unable to read from cache", "table", table);
      throw e;
    }

    return data;
  }

  static async write<T>(firebaseConnection: IFirebaseConnection, table: string, data: IListingReponse<T>): Promise<void> {
    const path = this.getPath(table);
    try {
      await firebaseConnection.database2.setValue(path, data);
    } catch (e){
      this._log.error("Unable to write to cache", "table", table);
      throw e;
    }
  }
}
