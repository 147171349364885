import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IEnumeratedConstant} from "../../../javascript.lib.mojo-base/model/EnumeratedConstant";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {MatDialog} from "@angular/material/dialog";
import {QuestionEnumDialogComponent} from "./question-enum-dialog/question-enum-dialog";
import {DialogData} from "../question-boolean/question-boolean-dialog/question-boolean-dialog";
import {DialogConfig} from "../../angular/DialogConfig";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";
import {BaseQuestion} from "../BaseQuestion";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";

/**
 * Generated class for the QuestionEnumComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'question-enum',
  templateUrl: 'question-enum.html',
  styleUrls: ['question-enum.scss']
})

export class QuestionEnumComponent extends BaseQuestion implements OnInit, OnDestroy {

  _log = LoggerFactory.build( 'QuestionEnumComponent' );

  selectedOption: IEnumeratedConstant|null = null;
  options: IEnumeratedConstant[] = [];

  @Input() set disabled(value: boolean) {
    super.disabled = value;
    if( this.disabled ) {
      this.answer.value.value = null;
      this.selectedOption = null;
    }
  }

  private _initSelectedOption() {
    const currentValue = this.answer.value.value as number;
    this._log.debug( 'currentValue', currentValue );
    if( this.answer.value.value ) {

      for( const option of this.options ) {

        if( currentValue == option.codeAsNumber ) {
          this.selectedOption =  option;
          break;
        }
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.validateType(AppQuestion.TYPE_ENUM);

    this.options = this.answer.question.value.type2.typeEnum.options;
    this._log.debug( 'this.options', this.options );

    this._initSelectedOption();

    this._log.debug( 'this.answer.dependant', this.answer.dependant );

    if( this.answer.dependant ) {
      this._dependantSubscription = this.answer.dependant.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy(this.answer.question.value.dependant);
        this._log.debug( 'isTruthy', isTruthy, dependant );
        this.disabled = !isTruthy;
      } );
    }

  }

  onOpenDialog(): void {
    const data: DialogData = {
      answer: this.answer
    };

    const dialogRef = this.dialog.open(QuestionEnumDialogComponent, {
      minWidth: DialogConfig.minWidth,
      panelClass: QuestionEnumDialogComponent.PANEL_CLASS,
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this._log.debug('dialog was closed');
      this._initSelectedOption();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  constructor(public dialog: MatDialog) {
    super();
  }
}
