import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogConfig} from "../../angular/DialogConfig";

export interface IAlertParams {
  title: string;
  message: string;
  okLabel: string;
}

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.html',
  styleUrls: ['alert-dialog.scss']
})
export class AlertDialogComponent {


  public static PANEL_CLASS = "confirm-cancel-dialog";

  params: IAlertParams;


  static show(dialog: MatDialog, title: string, message: string, okLabel = "OK" ): MatDialogRef<AlertDialogComponent> {

    const data: IAlertParams = {
      title,
      message,
      okLabel
    };

    const answer = dialog.open<AlertDialogComponent,IAlertParams,boolean>(AlertDialogComponent, {
      data,
      panelClass: AlertDialogComponent.PANEL_CLASS,
      minWidth: DialogConfig.minWidth,
    });

    return answer;
  }

  onOkClick(): void {
    this.dialogRef.close( true );
  }

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IAlertParams,
  ) {
    this.params = data;
  }
}
