import {ValueObject} from "../ValueObject";
import {AppQuestion, IAppDependantDescriptor} from "../AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {QuestionKey} from "../QuestionKey";

export interface IAppPageDefinition {
  title?: string;
  questionKeys: QuestionKey[];
  dependantDescriptor?: IAppDependantDescriptor;

  /**
   * for identification/persistence
   */
  sectionId?: string;
}


export class AppPageDefinition extends ValueObject<IAppPageDefinition> {

  private _log: ILogger = LoggerFactory.build( 'AppPageDefinition' );

  questions: AppQuestion[] = [];

  public static toValues(appPageModel: AppPageDefinition[] ): IAppPageDefinition[] {
    const answer: IAppPageDefinition[] = [];
    for( const section of appPageModel ) {
      answer.push( section.value )
    }
    return answer;
  }

  protected onSetValue(value: IAppPageDefinition | null) {
  }

  constructor( value: IAppPageDefinition ) {
    super(value);
  }
}



