<mat-sidenav-container aclass="sidenav-container">

  <mat-sidenav #sidenav aclass="sidenav" [mode]="'over'">
    <app-side-menu [sidenav]="sidenav"></app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>

    <div aclass="page-container">

      <mat-toolbar aclass="header-toolbar">
        <mat-toolbar-row >
          <button mat-button
                  (click)="sidenav.toggle()">
            <span class="material-icons">menu</span>
          </button>
          <span style="flex: 1 1 auto;"></span>
          <a class="mobility-mojo-logo" [routerLink]="['/home']">
            <img src="assets/mobility-mojo/logo-and-text.white.png"
                 aclass="header-toolbar-logo">
          </a>
          <span style="flex: 1 1 auto;"></span>
          <div style="width: 64px; height: 10px;">
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <div aclass="page-content">

        <app-session-checker *ngIf="!sessionContext.sessionIsReady"></app-session-checker>

      </div>

      <mat-toolbar aclass="footer-toolbar">
        <mat-toolbar-row>
        </mat-toolbar-row>
      </mat-toolbar>

    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
