import {Component, OnInit} from "@angular/core";
import {FirebaseConnectionService} from "../../service.firebase-connection/FirebaseConnectionService";
import {NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {AppMaintenance} from "../../../javascript.lib.mojo-base/model/AppMaintenance";
import {
  FirebaseMaintenance
} from "../../../javascript.lib.mojo-base/firebase/realtime-database/administration/FirebaseMaintenace";


@Component({
  selector: 'maintenance',
  templateUrl: 'maintenance.html',
  styleUrls: ['maintenance.scss'],
  imports: [
    NgIf,
    MatIconModule,
    MatButtonModule
  ],
  standalone: true
})
export class MaintenanceComponent implements OnInit {

  maintenanceMessages: AppMaintenance[] = [];

  public displayMessage: boolean = false;
  public message: string = "";
  public detail: string = "";

  constructor(public firebase: FirebaseConnectionService) {
  }

  onOkClick(): void {
    this.displayMessage = false;
  }

  async ngOnInit() : Promise<void> {

    this.maintenanceMessages = await FirebaseMaintenance.readAll(this.firebase);

    AppMaintenance.array.sortByEndDateDesc(this.maintenanceMessages);

    let current = new Date();

    for (let message in this.maintenanceMessages) {
      const maintenanceMessage = this.maintenanceMessages[message];
      const end = new Date(maintenanceMessage.value.end);

      // if the maintenance window is passed, can exit
      if (end < current) {
        break;
      }

      if (current < end) {
        const start = new Date(maintenanceMessage.value.start);
        this.message = `There will be scheduled maintenance between ${this.formatDateTime(start)} and ${this.formatDateTime(end)}. `
        this.detail = maintenanceMessage.value.detail;
        this.displayMessage = true;
        break;
      }
    }
  }

  private formatDateTime(date: Date): string {
    return `${date.toLocaleDateString(window.navigator.language)} ${date.toLocaleString(navigator.language, {hour: '2-digit', minute:'2-digit'})}`;
  }
}
