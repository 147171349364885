import {Component, Input} from "@angular/core";
import {AppProperty} from "../../../javascript.lib.mojo-base/model/AppProperty";
import {EProductType} from "../../../javascript.lib.mojo-base/model/ProductType";

@Component({
  selector: 'property-details',
  templateUrl: 'property-details.html',
  styleUrls: ['property-details.scss']
})
export class PropertyDetails {

    @Input() property: AppProperty;

    get label() : string {
      return (this.property.value.clientPropertyIdentifier != null && this.property.value.clientPropertyIdentifier.length > 0)
        ? `${this.property.value.name} [${this.property.value.clientPropertyIdentifier}]`
        : this.property.value.name;
    }

    get icon() : string {
      return (this.property.value.productType === EProductType.banking_2024)
        ? 'account_balance'
        : 'corporate_fare';
    }
}
