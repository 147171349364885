import {Component, Inject, Input} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogConfig} from "../../common/angular/DialogConfig";
import {AppClusterType} from "../../javascript.lib.mojo-base/model/app.cluster/AppClusterType";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ILogger} from "../../javascript.lib.mojo-base/log/Logger";
import {LoggerFactory} from "../../javascript.lib.mojo-base/log/LoggerFactory";


export interface IClusterAddDialogParams {

  // pageClusterView: IPageClusterView;

  name: string;
  clusterType: AppClusterType|null; // null if it is new
  responsibility: 'landlord'|'tenant';

  applicableTypes: AppClusterType[];
}

export interface IClusterAddDialogResponse {

  clusterType: AppClusterType;
  name: string;
  responsibility: 'landlord'|'tenant';

}



@Component({
  selector: 'cluster-add-dialog',
  templateUrl: 'cluster-add-dialog.html',
  styleUrls: ['cluster-add-dialog.scss']
})
export class ClusterAddDialogComponent {

  public static PANEL_CLASS = "cluster-detail-dialog";

  isNew: boolean;

  private _log: ILogger = LoggerFactory.build( 'ClusterDetailDialogComponent' );

  // sectionView: IPageClusterView;

  public params: IClusterAddDialogParams;
  public response: IClusterAddDialogResponse;


  formControls = {
    name: new FormControl(null, [ Validators.required ] ),
    responsibility: new FormControl( null, [ Validators.required ] ),
    clusterType: new FormControl( null,[ Validators.required ] ),
  };

  formGroup = new FormGroup({
    name: this.formControls.name,
    responsibility: this.formControls.responsibility,
    clusterType: this.formControls.clusterType,
  });


  onSendPasswordResetEmail() {

    this._log.debug( 'onSendPasswordResetEmail' );

  }

  static open(dialog: MatDialog, params: IClusterAddDialogParams ): MatDialogRef<ClusterAddDialogComponent> {

    const answer = dialog.open<ClusterAddDialogComponent, IClusterAddDialogParams, IClusterAddDialogResponse>
    ( ClusterAddDialogComponent,
      {
        data: params,
        panelClass: ClusterAddDialogComponent.PANEL_CLASS,
        minWidth: DialogConfig.minWidth,
      });

    return answer;
  }

  onCancelClick(): void {

    this.dialogRef.close(null );
  }

  onOkClick(): void {

    this.response.name = this.formControls.name.value;
    this.response.responsibility = this.formControls.responsibility.value;
    this.response.clusterType = this.formControls.clusterType.value;

    this.dialogRef.close( this.response );
  }


  constructor(
    public dialogRef: MatDialogRef<ClusterAddDialogComponent, IClusterAddDialogResponse>,
    @Inject(MAT_DIALOG_DATA) params: IClusterAddDialogParams,
  ) {

    this.params = params;
    this._log.debug( 'this.params', this.params );
    // this.sectionView = params.pageClusterView;

    if( params.clusterType ) {

      this.isNew = false;
      this.formControls.clusterType.disable();
    } else {

      this.isNew = true;
    }

    this.formControls.name.setValue( params.name );
    this.formControls.responsibility.setValue( params.responsibility );
    this.formControls.clusterType.setValue( params.clusterType );

    this._log.debug( 'this.formControls.responsibility.value', this.formControls.responsibility.value );

    this.response = {
      clusterType: params.clusterType,
      name: '',
      responsibility: 'landlord',
    }
  }

}
