import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";
import {MatDialog} from "@angular/material/dialog";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import firebase from "firebase/compat/app";
import {NgIf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";


@Component({
  selector: 'authenticate-google',
  templateUrl: 'authenticate-google.component.html',
  styleUrls: ['authenticate-google.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule
  ]
})

export class AuthenticateGoogleComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticateGoogleComponent' );

  @Output() signInCompleted = new EventEmitter<void>();

  private _provider: firebase.auth.GoogleAuthProvider;

  public authError: string = null;

  public async onAuthenticate() {

    this._log.debug( 'onAuthenticate');

    try {
      const userCredential: firebase.auth.UserCredential = await this.afAuth.signInWithPopup( this._provider );
      this._log.info( 'userCredential', userCredential );
    } catch (firebaseError) {
      this._log.error('firebaseError', firebaseError );
      return firebaseError as IFirebaseError;
    }
  }

  ngOnInit(): void {
    this._provider = new firebase.auth.GoogleAuthProvider()
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth,
               public dialog: MatDialog) {
  }
}
