import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {LoggerFactory} from "../../../../javascript.lib.mojo-base/log/LoggerFactory";
import {ILogger} from "../../../../javascript.lib.mojo-base/log/Logger";
import {IFirebaseError} from "../../../../javascript.lib.mojo-base/firebase/FirebaseAuthError";
import {BaseSessionContext} from "../../../service.session-context/BaseSessionContext";
import {NgIf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";


@Component({
  selector: 'authenticate-email-password',
  templateUrl: 'authenticate-email-password.component.html',
  styleUrls: ['authenticate-email-password.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class AuthenticateEmailPasswordComponent implements OnInit {

  private _log: ILogger = LoggerFactory.build( 'AuthenticateEmailPasswordComponent' );
  public hide = true;
  public authenticateError: string = null;

  emailPasswordControls = {
    email: new FormControl(null, [ Validators.required, Validators.email ] ),
    password: new FormControl(null, [Validators.required] )
  };

  emailPasswordFormGroup = new FormGroup({
    name: this.emailPasswordControls.email,
    password: this.emailPasswordControls.password,
  });

  @Output() signInCompleted = new EventEmitter<void>();
  @Output() passwordResetRequested = new EventEmitter<string>();

  public onResetPassword() {
    this.passwordResetRequested.emit(this.emailPasswordControls.email.value);
  }

  private _getEmailControlError(): string|null {
    if( this.emailPasswordControls.email.hasError( 'required')) {
      return 'Email address required';
    }
    if( this.emailPasswordControls.email.hasError( 'email')) {
      return 'Email address not valid';
    }
    return null;
  }


  async onEmailPasswordSubmit() {
    this.authenticateError = this._getEmailControlError();

    if(this.authenticateError) {
      return;
    }

    if( this.emailPasswordControls.password.hasError( 'required')) {
      this.authenticateError = 'Email is required';
      return;
    }

    const email = this.emailPasswordControls.email.value.trim();
    const password = this.emailPasswordControls.password.value.trim();
    const signInError: IFirebaseError|null = await this.sessionContext.signInWithEmailAndPassword(email, password);

    if(signInError) {
      this._log.logError( 'Login failed', (event) => {
        event.addContext( 'email', email );
        event.addContext( 'signInError.code', signInError.code );
        event.addContext( 'signInError.message', signInError.message );
      })
      this.authenticateError = "Login failed: Check email and password";
    } else {
      this.signInCompleted.emit();
    }
  }

  ngOnInit(): void {
  }

  constructor( public sessionContext: BaseSessionContext,
               public afAuth: AngularFireAuth) {
  }
}
