import {ValueObject} from "../../model/ValueObject";
import {NocoDbProjectProxy} from "../NocoDbProjectProxy";
import {IListingReponse} from "../ListingReponse";
import {NocoClusterId} from "./NocoCluster";
import {NocoEvaluationQuestion, NocoEvaluationQuestionId, NocoEvaluationQuestionSet} from "./NocoEvaluationQuestion";
import {NocoProductId} from "./NocoProduct";
import {NocoEvaluationSectionId} from "./NocoEvaluationSection";
import {NocoProductEvaluation2Id} from "./NocoProductEvaluation2";
import {AppPageDefinition} from "../../model/app/AppPageDefinition";
import {IAppDependantDescriptor} from "../../model/AppQuestion";
import {ILogger} from "../../log/Logger";
import {LoggerFactory} from "../../log/LoggerFactory";
import {AppCluster} from "../../model/app.cluster/AppCluster";
import {AppPageDefinitionSet} from "../../model/app/AppPageDefinitionSet";

export type NocoClusterQuestionId = number;

export interface INocoClusterQuestion {
  Id: NocoClusterQuestionId;
  ClusterId: NocoClusterId;
  PageNo: number;
  QuestionNo: number;
  QuestionId: NocoEvaluationQuestionId;
  // PageTitle: string;
  // DependencyMtid: string;
  // DependencyJson: string;
  // TempProductId: NocoProductId;
  // TempEvaluationSectionId: NocoEvaluationSectionId;
  // TempProductEvaluation2Id: NocoProductEvaluation2Id;

  // set at runtime if necessary ...
  ProductId: NocoProductId;
}

export class NocoClusterQuestion extends ValueObject<INocoClusterQuestion> {

  protected onSetValue(value: INocoClusterQuestion | null) {
  }

  constructor(value: INocoClusterQuestion | null) {
    super(value);
    if (value) {
      this.value = value;
    }
  }
}

export class NocoClusterQuestionSet {

  private static readonly tableName: string = 'cluster_question';

  private _log: ILogger = LoggerFactory.build( 'NocoClusterQuestion' );

  values: NocoClusterQuestion[] = [];
  valuesById: {[id: number]: NocoClusterQuestion} = {};

  pageDefinitionsForCluster( clusterTypeId: NocoClusterId, questions: NocoEvaluationQuestionSet ) {
    const subset: NocoClusterQuestion[] = [];
    for( const candidate of this.values ) {
      if( candidate.value.ClusterId === clusterTypeId ) {
        subset.push( candidate );
      }
    }

    subset.sort( ( a: NocoClusterQuestion, b: NocoClusterQuestion ) => {
      const pageSort = a.value.PageNo - b.value.PageNo;
      if( 0 !== pageSort ) {
        return pageSort;
      }
      return a.value.QuestionNo - b.value.QuestionNo;
    });

    const answerValue: AppPageDefinition[] = [];

    let currentPageNo = 0;
    let currentPage: AppPageDefinition = null;

    for( const clusterQuestion of subset ) {
      if( currentPageNo != clusterQuestion.value.PageNo ) {
        currentPage = new AppPageDefinition({
          questionKeys: [],
        });
        answerValue.push( currentPage );
        currentPageNo = clusterQuestion.value.PageNo;
      }

      const questionId = clusterQuestion.value.QuestionId;
      const evaluationQuestion: NocoEvaluationQuestion = questions.valuesById[questionId];

      if( !evaluationQuestion ) {
        this._log.error( '!evaluationQuestion', 'questionId', questionId );
        continue;
      }

      const questionKey = evaluationQuestion.value.MysteriousTechColumn.trim();
      currentPage.value.questionKeys.push(questionKey);
    }
    return new AppPageDefinitionSet(answerValue);
  }

  toPageDefinitions( cluster: AppCluster, questions: NocoEvaluationQuestionSet ): AppPageDefinitionSet {
    return this.pageDefinitionsForCluster( cluster.value.clusterTypeId, questions );
  }

  public static async getValue( proxy: NocoDbProjectProxy ): Promise<IListingReponse<INocoClusterQuestion>> {
    return proxy.getView<INocoClusterQuestion>(this.tableName);
  }

  public static async build( proxy: NocoDbProjectProxy ): Promise<NocoClusterQuestionSet> {
    const value: IListingReponse<INocoClusterQuestion> = await proxy.getView<INocoClusterQuestion>(this.tableName);
    return new NocoClusterQuestionSet( value );
  }

  public constructor( public value: IListingReponse<INocoClusterQuestion> ) {
    for( const rowValue of value.list ) {
      const reference = new NocoClusterQuestion( rowValue );
      this.values.push( reference );
      this.valuesById[rowValue.Id] = reference;
    }
  }
}

