import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {BaseApplicationContext} from "../common/services.application-context/BaseApplicationContext";

@Injectable()
export class ApplicationContextProvider extends BaseApplicationContext {

  constructor() {

    super();

    // is it live ?
    if( 0 === window.location.protocol.indexOf( "https") ) {
      this.isProduction = true;
      this.isTest = false;
    } else if( "10000" === window.location.port ) { // dev ?
      this.isProduction = false;
      this.isTest = true;
    } else { // fall back to production
      this.isProduction = true;
      this.isTest = false;
    }
  }
}
