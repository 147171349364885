import {ValueObject} from './ValueObject';
import {IAppDependantDescriptor, AppQuestion, IScoreForNumberRange} from './AppQuestion';
import {ValueHelper} from "../util/ValueHelper";
import {IQuestionAnswer} from "./QuestionAnswer";
import {BehaviorSubject, Subscription} from 'rxjs';
import {EnumeratedConstantReference, IEnumeratedConstant} from "./EnumeratedConstant";
import {ILogger} from "../log/Logger";
import {LoggerFactory} from "../log/LoggerFactory";
import {QuestionKey} from "./QuestionKey";
import {EnumeratedAnswer} from "./EnumeratedAnswer";
import {AppTypedReference, IAppTypedReference} from "./cg/core/AppTypedReference";
import {EAppReferenceType} from "./cg/core/AppReferenceType";
import {MMBaseException} from "../util/MMBaseException";


export interface IAppAnswer {
  $key?: string;
  questionKey: QuestionKey; // uuid
  friendlyLabel?: string;
  needsAnswer: boolean;
  hasAnswer: boolean;
  value: any;
  score: number;
}

export class AppAnswer extends ValueObject<IAppAnswer> implements IQuestionAnswer {

  private static _log: ILogger = LoggerFactory.build( 'AppAnswer' );

  answer: AppAnswer;

  private _photoKeys: number[];
  private _dependant: AppAnswer|null = null;
  public dependantSubscription: Subscription|null = null;

  // only set if the answer is of type enum
  private _enumAnswer: EnumeratedAnswer|null = null;
  private _typedReference: AppTypedReference|null = null;

  get dependant(): AppAnswer {
    return this._dependant;
  }

  set dependant( dependant: AppAnswer|null )  {
    // AnswerReference._log.debug( 'set dependant', dependant );
    // no-op ?
    if( this._dependant === dependant ) {
      return;
    }

    if( this.dependantSubscription ) {
      this.dependantSubscription.unsubscribe();
    }

    this._dependant = dependant;
    if( dependant ) {
      this.dependantSubscription = dependant.getSubject().subscribe( (value: AppAnswer) => {

        // AnswerReference._log.debug( 'this.question.value.dependant', this.question.value.dependant );
        if( !value.isTruthy( this.question.value.dependant ) ) {
          this.value.value = null;
          this.value.hasAnswer = false;

          // hacky way to get the subscription to trigger ...
          this.value.value = this.value.value;
        }
      } );
    }
  }

  private _subject: BehaviorSubject<AppAnswer> = null;

  public getSubject(): BehaviorSubject<AppAnswer> {
    if( !this._subject ) {
      this._subject = new BehaviorSubject<AppAnswer>( this );
    }
    return this._subject;
  }

  getEnumOption(): IEnumeratedConstant|null {
    return this._enumAnswer.getEnumOption( this );
  }

  getEnumScore(): number {
    return this._enumAnswer.getScore( this );
  }

  isTruthy( dependantDescriptor: IAppDependantDescriptor ): boolean {
    if ( this.question.isBoolean ) {
      const value = this.value.value as boolean;

      if( 'undefined' !== typeof dependantDescriptor.falsyBooleanValue ) {
        return value !== dependantDescriptor.falsyBooleanValue;
      }

      if( 'undefined' !== typeof dependantDescriptor.truthyBooleanValue ) {
        return value === dependantDescriptor.truthyBooleanValue;
      }

      return value;
    }

    if( this.question.isEnum ) {
      return this._enumAnswer.isTruthy( this, dependantDescriptor );
    }

    if( this.question.isInteger ) {
      const value = ValueHelper.valueToInteger(this.value.value);

      if( null === value ) {
        AppAnswer._log.warn( 'null === value', this );
        return false;
      }

      return 0 !== value;
    }

    if( this.question.isTernary ) {
      const value = this.value.value as number;

      if( dependantDescriptor.truthyTernaryCodes ) {
        for( const candidate of dependantDescriptor.truthyTernaryCodes ) {
          if( candidate === value ) {
            return true;
          }
        }
        return false;
      }

      if( EnumeratedConstantReference.yes.codeAsNumber === value ) {
        return true;
      }

      return false;
    }

    return false;
  }

  public toAnswerReference(): AppTypedReference {

    if( !this._typedReference ) {
      let type = null;
      const question = this.question;
      if( question.isBoolean ) {
        type = EAppReferenceType.answer_boolean;
      } else if( question.isEnum ) {
        type = EAppReferenceType.answer_enum;
      } else if( question.isFloat ) {
        type = EAppReferenceType.answer_float;
      } else if( question.isInteger ) {
        type = EAppReferenceType.answer_integer;
      } else if( question.isLine ) {
        type = EAppReferenceType.answer_line;
      } else if( question.isCmMeasurement ) {
        type = EAppReferenceType.answer_measurement;
      } else if( question.isPhoto ) {
        type = EAppReferenceType.answer_photo;
      } else if( question.isTernary ) {
        type = EAppReferenceType.answer_ternary;
      } else if( question.isText ) {
        type = EAppReferenceType.answer_text;
      }

      if( null === type ) {
        throw  MMBaseException.build( "Answer", "null === type", {
          questionValue:  question.value
        });
      }

      const value: IAppTypedReference = {
        type,
        id: question.value.key
      };

      this._typedReference = new AppTypedReference( value );
    }

    return this._typedReference;
  }

  removePhotoKeyAtIndex( index: number ) {
    const photoKeys = this.getPhotoKeys();
    photoKeys.splice( index, 1 );
  }

  addPhotoKey(): number {
    const now = new Date();
    const photoKey = now.getTime();
    this.getPhotoKeys().push( photoKey );
    return photoKey;
  }

  getPhotoKeys(): number[] {
    if ( this._photoKeys ) {
      return this._photoKeys;
    }

    if ( this.value && this.value.value ) {
      // old school index ...
      if ( 'string' === typeof this.value.value || 'number' === typeof this.value.value ) {
        const count: number = ValueHelper.getIntegerValue( this.value , 0);
        this._photoKeys = [];
        this.value.value = this._photoKeys; // over-ride the old numeric value

        for (let i = 0; i < count; i++) {
          this._photoKeys.push( i );
        }
      } else if ( 'object' === typeof this.value.value && 'number' === typeof this.value.value.length ) { // new skool array

        this._photoKeys = this.value.value;
      }
    } else {

      this._photoKeys = [];
      this.value.value = this._photoKeys;
    }
    return this._photoKeys;
  }


  protected onSetValue( value: IAppAnswer | null) {
    this._photoKeys = null;
    if( this._enumAnswer ) {
      this._enumAnswer.onSetValue( value );
    }
    if( this._subject ) {
      this._subject.next( this );
    }
    console.log("setting score...");
    this.value.score = 10;
  }

  constructor( public question: AppQuestion,
               value: IAppAnswer = null ) {
    super( value );

    if( question.isEnum ) {
      this._enumAnswer = new EnumeratedAnswer( question );
    }

    this.answer = this;

    if ( value ) {
      this.value = value;
    } else {
      this.value = {
        friendlyLabel: question.value.friendlyLabel ?? null,
        questionKey: question.value.key,
        needsAnswer: true,
        hasAnswer: false,
        value: null,
        score: 0,
      };
    }
  }
}
