import {LocalStore} from "../common/util/LocalStore";

export class AppStorage {

  private static getName(prefix: string) {
    return (prefix)
      ? `${prefix}_AppStorage.PropertyKey`
      : "AppStorage.PropertyKey";
  }

  public static setPropertyKey(prefix: string = null, propertyKey: string ) {
    const store = new LocalStore();
    store.setItem(this.getName(prefix), propertyKey );
  }

  public static getPropertyKey(prefix: string = null): string|null {
    const store = new LocalStore();
    return store.getItem(this.getName(prefix));
  }

  public static clear(prefix: string = null) {
    const store = new LocalStore();
    return store.removeItem(this.getName(prefix));
  }
}
