import {ActivatedRoute, Router} from '@angular/router';
import {RouteDescriptor} from "../common/util/RouteDescriptor";
import {AppTypedReferenceString} from "../javascript.lib.mojo-base/model/cg/core/AppTypedReference";



export class AppRouteManifest {

  static readonly ASPECT_SECTION = new class extends RouteDescriptor {

    navigateToPage(router: Router, aspectIndex: number, sectionIndex: number ) {
      const params = {
        aspectIndex,
        sectionIndex
      };

      this.navigate( router, params );
    }

    async getAspectIndex( route: ActivatedRoute ): Promise<number> {

      const aspectIndex: string = await this.getParam( route, 'aspectIndex' );
      if( !aspectIndex ) {
        return 0;
      }
      return Number.parseInt( aspectIndex );
    }

    async getSectionIndex( route: ActivatedRoute ): Promise<number> {

      const aspectIndex: string = await this.getParam( route, 'sectionIndex' );
      if( !aspectIndex ) {
        return 0;
      }
      return Number.parseInt( aspectIndex );
    }

    constructor() {

      super('aspect/:aspectIndex/:sectionIndex' );
    }
  };

  static readonly CLUSTER_PAGE = new class extends RouteDescriptor {

    navigateToPage(router: Router, clusterId: AppTypedReferenceString, activeIndex: number, sectionIndex: number, subSectionIndex: number
    ) {

      const params = {
        clusterId,
        activeIndex,
        sectionIndex,
        subSectionIndex,
      };

      this.navigate( router, params );
    }

    async getClusterId(route: ActivatedRoute ): Promise<AppTypedReferenceString|null> {
      return this.getParam( route, 'clusterId' );
    }

    async getActiveIndex(route: ActivatedRoute ): Promise<number> {
      return await this.getNamedIndex( route, 'activeIndex' );
    }

    async getSectionIndex(route: ActivatedRoute ): Promise<number> {
      return await this.getNamedIndex( route, 'sectionIndex' );
    }

    async getSubSectionIndex(route: ActivatedRoute ): Promise<number> {
      return await this.getNamedIndex( route, 'subSectionIndex' );
    }

    private async getNamedIndex(route: ActivatedRoute, indexName: string): Promise<number> {
      const index: string = await this.getParam( route, indexName );
      if( !index ) {
        return 0;
      }
      return Number.parseInt( index );
    }

     constructor() {
      super('cluster-page/:clusterId/:activeIndex/:sectionIndex/:subSectionIndex');
    }
  };

  static readonly DOCUMENTS = new RouteDescriptor('documents' );

  static readonly HOME = new RouteDescriptor('home' );

  static readonly QUESTION_TEST = new class extends RouteDescriptor {

    async getQuestionId( route: ActivatedRoute ): Promise<string|null> {

      return this.getParam( route, 'questionId' );
    }

    constructor() {
      super('question-test/:questionId' );
    }
  }

  static readonly PASSWORD_RESET = new class extends RouteDescriptor {

    navigateToPage(router: Router, emailAddress: string  ) {
      const params = {
        emailAddress
      };

      this.navigate( router, params );
    }

    async getEmailAddress( route: ActivatedRoute ): Promise<string|null> {
      return this.getParam( route, 'emailAddress' );
    }


    constructor() {
      super('password-reset/:emailAddress');
    }
  };

  static readonly SELECT_PROPERTY = new RouteDescriptor('select-property' );

  static readonly AUDIT = new class extends RouteDescriptor {

    navigateToPage(router: Router, propertyKey: string|null) : void {
      const params = {
        propertyKey
      };
      this.navigate(router, params);
    }

    async getPropertyKey(route: ActivatedRoute) : Promise<string|null> {
      return this.getParam(route, 'propertyKey');
    }

    constructor() {
      super('audit/:propertyKey');
    }
  };

  static readonly SHAREABLE_LINK = new class extends RouteDescriptor {

    navigateToPage(router: Router, propertyKey: string|null) : void {
      const params = {
        propertyKey
      };
      this.navigate(router, params);
    }

    async getPropertyKey(route: ActivatedRoute) : Promise<string|null> {
      return this.getParam(route, 'propertyKey');
    }

    constructor() {
      super('for/:propertyKey');
    }
   };

  static readonly SCORE = new RouteDescriptor('score' );



}



