


export class CGIdentifierGenerator {


  private static _EPOC = new Date( '2023-10-10');
  private static _lastId = 0;

  // [Crockford's Base32](https://en.wikipedia.org/wiki/Base32#Crockford's_Base32)
  static readonly DIGITS = '0123456789ABCDEFGHJKMNPQRSTVWXYZ';

  static encode(value: number) {

    let result = '', mod;
    do {
      mod = value % 32;
      result = CGIdentifierGenerator.DIGITS.charAt(mod) + result;
      value = Math.floor(value / 32);
    } while(value > 0);

    return result;
  };


  static decode(value: string): number {

    let result = 0;
    for (let i = 0, len = value.length; i < len; i++) {
      result *= 32;
      result += CGIdentifierGenerator.DIGITS.indexOf(value[i]);
    }

    return result;
  };


  public static generateId(): string {
    const now = new Date();
    let numericId = now.getTime() - CGIdentifierGenerator._EPOC.getTime();

    if( this._lastId > numericId ) {
      numericId = this._lastId + 1;
    }
    this._lastId = numericId;

    return CGIdentifierGenerator.encode( numericId );
  }
}
