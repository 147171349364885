export class MatIcons {
  public static icons = {
    audit: "assignment",
    report: "pie_chart",
    users: "groups",
    user: "person",
    clients: "groups",
    properties: "business",
    home: "home",
    logout: "logout",
    backOffice: "admin_panel_settings",
    maintenance: "engineering",
  };
}
